<template>
	
    <div id="wrap">
		<div id="map" class="map_main"></div>
		<!-- 이용방법 -->
		<div id="user_face" v-show="showUserFace == true">
			<div class="face_masssge">
				<p class="top_face face1">좌측 상단버튼을 터치하시면 좌측에서 메뉴가 펼쳐집니다.</p>
				<p class="top_face face2">알림내용은 여기서 확인하세요!</p>
				<p class="bot_face face3">검색 또는 주변, 즐겨찾기, 최근 탭을 터치하면 확인할수 있어요.</p>
				<p class="bot_face face4">방문관리페이지에 바로 접속되어 방문객 승인, 관리를 할수있어요.</p>
			</div>
			<button class="btn face_close" @click="closeUserFace">닫기</button>
		</div>
		<div class="top_bg">
			<div class="top_area">
				<div class="t_search">
					<button class="left_btn" id="left_btn" @click="didClickSideMenu">
						<span class="line"></span>
						<span class="line"></span>
						<span class="line"></span>
					</button>
					<input type="text" class="place_form" placeholder="주소 / 장소검색" @click="didClickSearch" />
				</div>

				<div class="t_alarm" v-show="showAlarm == true" :class="{on: newAlarm.length > 0}">
						<button class="t_alarm_btn">알람버튼</button>
					</div>
			</div>
		</div>
		<loader  v-show="showLoader == true" object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="1.3" bg="#343a40" objectbg="#999793" opacity="70" disableScrolling="false" name="dots"></loader>

		<SideMenu :showSideMenu="showSideMenu" :sideCompList="compList" @closeSideMenu="closeSideMenu"  @clickUserFace="didClickUserFace"></SideMenu>
		<HomeBottomList :showBotMenu="showBotMenu" @closeBotMenu="closeBotMenu" :ListIndex="ListIndex" :bottomItems="bottomItems" ></HomeBottomList>
		<MapCompanyList :showMapCompany="showMapCompany" @closeMapCompany="closeMapCompany" @clickMapCompanyParentFunction="clickMapCompany"
			:isCustomer="isCustomer" :markerSeq="markerSeq" :mapCompItems="mapCompItems" ref="MapCompanyList"></MapCompanyList>
		<NoDataCompany :showNoDataCompany="showNoDataCompany" @closeNoDataCompany="closeNoDataCompany">
		</NoDataCompany>

		<div class="bot_bg" >
			<ul>
				<li class="m_location" :class="{on: ListIndex === 0}"><a @click="clickBotMenu(0)">주변</a></li>
				<li class="m_bookmark" :class="{on: ListIndex === 1}"><a @click="clickBotMenu(1)">즐겨찾기</a></li>
				<li class="m_latest" :class="{on: ListIndex === 2}"><a @click="clickBotMenu(2)">최근</a></li>
			</ul>
		</div>
		<div class="modal_bg" v-if="showSideMenu == true"></div>
		<!--
		<div class="btn-box">
			<button @click="clickMapCompany(true)">고객사</button>
			<button @click="clickMapCompany(false)">비고객사</button>
			<button @click="clickNoDataCompany">지역정보가 없는 경우</button>
		</div>
		-->

        
    </div>
</template>
<script>

	var nowLat = "";
	var nowLon = "";
	var markerSeq = "";
	
    import NoDataCompany from './views/NoDataCompany'
    import MapCompanyList from './views/MapCompanyList'
    import SideMenu from './layout/SideMenu'
    import HomeBottomList from './views/HomeBottomList'
    import {
		getBottomCompList,
		getFavoriteList,
		getCompInfoList,
		getCompList,
		getRecentSearchHistory
    } from '@/api/common'
    export default {
        name: "Home",
        components: {
            SideMenu,
            HomeBottomList,
            MapCompanyList,
			NoDataCompany			
        },
        data() {
            return {
                ListIndex: 4,
                showAlarm: false,
                newAlarm: ['1','2'],
                showUserFace: false,
                showBotMenu: false,
                showSideMenu: false,
                showMapCompany: false,
                isCustomer: true,
				showLoader: true,
				showNoDataCompany: false,
				compList : [],
				bottomItems: [],
				mapCompItems: [],
				longitude: this.$route.params.lon,
				latitude: this.$route.params.lat,
				noMark: true
            }
        },
        methods: {
            didClickUserFace(){
                this.showUserFace = true
            },
			//현재장소 보기
            clickNoDataCompany() {

				console.log("clickNoDataCompany 현재장소 보기");
                this.showNoDataCompany = true
                this.showBotMenu = false
                this.showMapCompany = false
            },
            closeNoDataCompany() {
                this.showNoDataCompany = false
            },
            clickMapCompany(isCustomer) {

				console.log("clickMapCompany : " + isCustomer);

				let form = new FormData()
				form.append('seq', markerSeq)
				form.append('tel', localStorage.getItem('tel'))

				console.log("form : " + form);
				
				getCompList(form).then(response => {
					console.log(response)
					if(response.status == 200){

						this.mapCompItems = response.data.list;

						this.$refs.MapCompanyList.closeMapShare();

						console.log("this.mapCompItems  : " + this.mapCompItems.length );
					}
				})
				
                this.showMapCompany = true
                this.isCustomer = isCustomer
                this.showNoDataCompany = false
                this.showBotMenu = false
            },
            closeMapCompany() {
                this.showMapCompany = false
            },
            closeUserFace() {
                this.showUserFace = false
            },
            didClickSearch() {
                this.$router.push({
					name: 'HOMESEARCH',
					params: {compItems: this.compList, pgFlag : 'H'}
                })
            },
            didClickSideMenu() {
                console.log('didClickSideMenu')
                this.showSideMenu = true
                this.showBotMenu = false
                this.showMapCompany = false
                this.showNoDataCompany = false
            },
            closeSideMenu() {
                this.showSideMenu = false
            },
            didVisitGoBtn(comp_url){

				console.log("comp_url :" + comp_url);

				try {
					var message = {action: "visitGo", shareUrl: comp_url };
					window['webkit'].messageHandlers['cordova_iab'].postMessage(JSON.stringify(message));
				} catch (err) {
					window.open(comp_url, "_blank")
				}

            },
            clickBotMenu(idx) {

				//bottom 메뉴 선택시 bomttom 팝업이 노출되어 있음 숨김
				if(this.ListIndex == idx && this.showBotMenu){
					this.closeBotMenu();
					return false;
				}

				if(idx == 0){

					let form = new FormData()
					form.append('lat', nowLat)
					form.append('lon', nowLon)
					form.append('tel', localStorage.getItem('tel'))
					
					getBottomCompList(form).then(response => {
						console.log(response)
						if(response.status == 200){

							this.bottomItems = response.data.list;
						}
					})
				}else if(idx == 1){

					let form = new FormData()
					form.append('tel', localStorage.getItem('tel'))
					
					getFavoriteList(form).then(response => {
						console.log(response)
						if(response.status == 200){

							this.bottomItems = response.data.list;
						}
					})
				}else if(idx == 2){
					
					let form = new FormData()
					form.append('tel', localStorage.getItem('tel'))

					getRecentSearchHistory(form).then(response => {
						console.log(response)
						if(response.status == 200){

							this.bottomItems = response.data.list;
						}
					})
				}
				
                this.ListIndex = idx
                this.showBotMenu = true
                this.showMapCompany = false
                this.showNoDataCompany = false
            },
            closeBotMenu() {
                this.showBotMenu = false
            },
            clickGoHome(comp_lon, comp_lat) {
                this.closeBotMenu();

				this.latitude = comp_lat;
				this.longitude = comp_lon;


				this.loadInit();
            },
			loadInit(){
				const self = this


				const markers = [];

				console.log("loadInit  ");

				let form = new FormData()
				form.append('tel', localStorage.getItem('tel'))

				getCompInfoList(form).then(response => {
					console.log(response)
					if(response.status == 200){
						
						this.compList = response.data.list;

						console.log("getCompInfoList this.compList.length: " +this.compList.length);

						

						for (let i = 0; i < this.compList.length; i++) {

							if(this.compList[i].comp_flag == "C"){

								//console.log("this.compList[i].comp_name.length > " + this.compList[i].comp_name.length);
								var tmpCss = "mark_txt_130";
								if(this.compList[i].comp_name.length > 6){
									tmpCss = "mark_txt_160";
								}
								if(this.compList[i].comp_name.length > 12){
									tmpCss = "mark_txt_190";
								}
								if(this.compList[i].comp_name.length > 16){
									tmpCss = "mark_txt_220";
								}
								// 엔로비 고객사 다수
								const greenMarker = new naver.maps.Marker({
									position: new naver.maps.LatLng(this.compList[i].comp_lat, this.compList[i].comp_lon),
									map: map,
									title: 4,
									icon: {

										content: [
											'<div class="mapbridge green_nlobby">',
											'<div class="map_group _map_group">',
											'<div class="map_marker _marker"> ',
											'<span class="ico _icon"></span>',
											'<span class="'+tmpCss+'" id="'+this.compList[i].comp_url+'">'+this.compList[i].comp_name+'</span>',
											'<span class="shd">'+this.compList[i].seq+'</span>',
											"</div>",
											"</div>",
											"</div>",
											
										].join(""),
										size: new naver.maps.Size(40, 45),
										anchor: new naver.maps.Point(19, 40),
									},
									draggable: false,
								});


								markers.push(greenMarker);

								//고객사 클릭
								var greenMarkerListener = naver.maps.Event.addListener(greenMarker, 'click', function(event) {

									if(event.pointerEvent.target.id != ""){
										self.didVisitGoBtn(event.pointerEvent.target.id);
									}else{
										markerSeq = event.pointerEvent.target.innerText;

										//markerSeq.isInteger
										if(markerSeq > 0){

											self.clickMapCompany(true);
										}
									}

									//naver.maps.Event.removeListener(listener);
								});
							}else if(this.compList[i].comp_flag == "N"){

								tmpCss = "mark_txt_130";
								if(this.compList[i].comp_name.length > 6){
									tmpCss = "mark_txt_160";
								}
								if(this.compList[i].comp_name.length > 12){
									tmpCss = "mark_txt_190";
								}

								if(this.compList[i].comp_name.length > 16){
									tmpCss = "mark_txt_220";
								}

								//  비고객사 다수
								const grayMarker = new naver.maps.Marker({
									position: new naver.maps.LatLng(this.compList[i].comp_lat, this.compList[i].comp_lon),
									map: map,
									icon: {
										content: [
											'<div class="mapbridge gray_nlobby">',
											'<div class="map_group _map_group">',
											'<div class="map_marker _marker"> ',
											'<span class="ico _icon"></span>',
											'<span class="'+tmpCss+'">'+this.compList[i].comp_name+'</span>',
											'<span class="shd">'+this.compList[i].seq+'</span>',
											"</div>",
											"</div>",
											"</div>",
										].join(""),
										size: new naver.maps.Size(40, 45),
										anchor: new naver.maps.Point(19, 40),
									},
									draggable: false,
								});


								markers.push(grayMarker);

								//비고객 클릭 
								var grayMarkerListener = naver.maps.Event.addListener(grayMarker, 'click', function(event) {

									if(event.pointerEvent.target.id != ""){
										self.didVisitGoBtn(event.pointerEvent.target.id);
									}else{
										markerSeq = event.pointerEvent.target.innerText;
										
										if(markerSeq > 0){
											self.clickMapCompany(true);
										}
									}
								});
							}
						}
						

						if (navigator.geolocation) {
							navigator.geolocation.getCurrentPosition(
								onSuccessGeolocation,
								onErrorGeolocation
							);
						} else {
							const center = map.getCenter();
						}						
					}



				})
				const locationNewBtnHtml =
					'<p class="location_new_ico">현재위치 아이콘</p>';
				const locationBtnHtml =
					'<p class="location_ico">현재위치 아이콘</p>';
				const linkBtnHtml =
					'<p class="adm_ico"><a href="https://demo.nlobby.com/dash">방문관리 아이콘</a></p>';
				const redMarker =
					'<div class="mapbridge no_mark" id="noMark" style="display:none"><div class="map_group _map_group"><div class="map_marker _marker noa"><span class="ico _icon"></span><span class="shd">마커</span></div></div></div>';

				if(self.latitude == ""){

					console.log("self.latitudeself.latitudeself.latitude");


					self.latitude = position.coords.latitude;
					self.longitude = position.coords.longitude;
					self.showLoader = true;
				}

				document.getElementById("map").innerHTML = "";

				const map = new naver.maps.Map("map", {
					center: new naver.maps.LatLng(self.latitude , self.longitude), //지도의 초기 중심 좌표
					zoom: 10, //지도의 초기 줌 레벨
					useStyleMap: true,
					disableKineticPan: false,
					mapDataControl: false,
					clickable: true,
					mapTypes: new naver.maps.MapTypeRegistry({
						normal: naver.maps.NaverStyleMapTypeOptions.getNormalMap({
							overlayType: "bg.ol.ts.lko",
						}),
					}),
				});

				const nodataAddress = document.getElementById("nodata_com");

				function searchCoordinateToAddress(latlng) {
					//infoWindow.close();

					
					naver.maps.Service.reverseGeocode({
							coords: latlng,
							orders: [
								naver.maps.Service.OrderType.ADDR,
								naver.maps.Service.OrderType.ROAD_ADDR,
							].join(","),
						},
						function (status, response) {
							
							if (status === naver.maps.Service.Status.ERROR) {
								if (!latlng) {
									return alert("ReverseGeocode Error, Please check latlng");
								}
								if (latlng.toString) {
									return alert("ReverseGeocode Error, latlng:" + latlng.toString());
								}
								if (latlng.x && latlng.y) {
									return alert(
										"ReverseGeocode Error, x:" + latlng.x + ", y:" + latlng.y
									);
								}
								return alert("ReverseGeocode Error, Please check latlng");
							}

							//클립했을때 마커 이동 및 지도 이동
							map.setCenter(latlng);

							const address = response.v2.address,
								htmlAddresses = [];
							if (address.jibunAddress !== "") {
								htmlAddresses.push("[지번 주소] " + address.jibunAddress);
							}
							let htmlAddresses2 = [];
							if (address.roadAddress !== "") {
								htmlAddresses2.push("[도로명 주소] " + address.roadAddress);
							}

							nodataAddress.innerHTML =
								'<button class="close_btn">X</button>' +
								'<div class="list_title" id="list_title">' +
								'<h4 class="place_name">' + '현재위치' + '</h4>' +
								'<p class="place_address">' +
								'<span class="bunji">' +
								htmlAddresses +
								"</span>" +
								'<span class="road">' +
								htmlAddresses2 +
								"</span>" +
								"</p>" +
								'<div class="place_plus">' +
								'<p class="place_add_btn">' +
								'<a href="savevisitPlace?addr='+address.roadAddress+'&lon='+latlng.x +'&lat='+latlng.y +'">방문장소 만들기</a>' +
								"</p>" +
								"</div>" +
								"</div>";
						}
					);
				}

				function searchAddressToCoordinate(address) {
					naver.maps.Service.geocode({
							query: address,
						},
						function (status, response) {
							if (status === naver.maps.Service.Status.ERROR) {
								if (!address) {
									return alert("Geocode Error, Please check address");
								}
								return alert("Geocode Error, address:" + address);
							}
							if (response.v2.meta.totalCount === 0) {
								return alert("No result.");
							}
							const htmlAddresses = [],
								item = response.v2.addresses[0],
								point = new naver.maps.Point(item.x, item.y);

							if (item.roadAddress) {
								htmlAddresses.push("[도로명 주소] " + item.roadAddress);
							}
							if (item.jibunAddress) {
								htmlAddresses.push("[지번 주소] " + item.jibunAddress);
							}
							if (item.englishAddress) {
								htmlAddresses.push("[영문명 주소] " + item.englishAddress);
							}
						}
					);
				}

				function initGeocoder() {
					if (!map.isStyleMapReady) {
						return;
					}

					map.addListener("pinchend", function (e) {
						
						console.log("map zoom "  + map.getZoom())
						//searchCoordinateToAddress(e.coord);
					});

					// map.addListener("touchstart", function (e) {
					// 	sFunction();
					// });

					map.addListener("dragend", function (e) {
						sFunction();
					});

					function sFunction() {

						var htmlMarker1 = {
								content: '<div class="cluster-marker-1"></div>',
								size: N.Size(40, 40),
								anchor: N.Point(20, 20)
							},
							htmlMarker2 = {
								content: '<div class="cluster-marker-2"></div>',
								size: N.Size(40, 40),
								anchor: N.Point(20, 20)
							},
							htmlMarker3 = {
								content: '<div class="cluster-marker-3"></div>',
								size: N.Size(40, 40),
								anchor: N.Point(20, 20)
							};
					

						var markerClustering = new MarkerClustering({
							minClusterSize: 2,
							maxZoom: 13,
							map: map,
							markers: markers,
							disableClickZoom: false,
							gridSize: 120,
							icons: [htmlMarker1, htmlMarker2, htmlMarker3],
							indexGenerator: [10, 50, 100],
							stylingFunction: function(clusterMarker, count) {
								$(clusterMarker.getElement()).find('div:first-child').text(count);
							}
						});
					}

					// map.addListener(map, 'idle', function() {
					// 	updateMarkers(map, markers);
					// });

					function updateMarkers(map, markers) {

						var mapBounds = map.getBounds();
						var marker, position;

						for (var i = 0; i < markers.length; i++) {

							marker = markers[i]
							position = marker.getPosition();

							if (mapBounds.hasLatLng(position)) {
								showMarker(map, marker);
							} else {
								hideMarker(map, marker);
							}
						}
					}

					function showMarker(map, marker) {

						if (marker.getMap()) return;
						marker.setMap(map);
					}

					function hideMarker(map, marker) {

						if (!marker.getMap()) return;
						marker.setMap(null);
					}

					function handleEvent(e) {

						var position = map.getCenter();
						
						searchCoordinateToAddress(position);
					}


					map.addListener('dragend', handleEvent);

					$("#searchbox").on("keydown", function (e) {
						const keyCode = e.which;

						if (keyCode === 13) {
							// Enter Key
							searchAddressToCoordinate($("#searchbox").val());
						}
					});

					$("#submit").on("click", function (e) {
						e.preventDefault();
						searchAddressToCoordinate($("#searchbox").val());
					});
					//searchAddressToCoordinate('정자동 178-1');
				}

				naver.maps.onJSContentLoaded = initGeocoder;
				naver.maps.Event.once(map, "init_stylemap", initGeocoder);

				// const infowindow = new naver.maps.InfoWindow();
				function onSuccessGeolocation(position) {

					if(self.latitude == undefined){
						nowLat = position.coords.latitude;
						nowLon = position.coords.longitude;

						// try {
						// 	document.getElementById("noMark").style.display="none";
						// }catch (exception) { 
						// 	console.log("exception " + exception);
						// }

						

					}else{
						nowLat = self.latitude ;
						nowLon = self.longitude ;

						// try {
						// 	document.getElementById("noMark").style.display="none";
						// }catch (exception) { 
						// 	console.log("exception " + exception);
						// }
						
					}


					const location = new naver.maps.LatLng(
						nowLat,
						nowLon
					);

					console.log("position.coords.latitude : " +nowLat);
					console.log("position.coords.longitude : " +nowLon);

					self.latitude = nowLat;
					self.longitude = nowLon;

					searchCoordinateToAddress(location);

					map.setCenter(location); // 얻은 좌표를 지도의 중심으로 설정합니다.
					map.setZoom(16); // 지도의 줌 레벨을 변경합니다.

					// 녹색아이콘
					// const myLocation = new naver.maps.Marker({
					// 	position: location,
					// 	map: map,
					// 	icon: {
					// 		content: [
					// 			'<div class="mypoint">',
					// 			'<p class="point_area">',
					// 			"현재위치",
					// 			"</p>",
					// 			"</div>",
					// 		].join(""),
					// 		size: new naver.maps.Size(45, 50),
					// 		anchor: new naver.maps.Point(25, 45),
					// 	},
					// 	draggable: false,
					// });

					self.showLoader = false;
				}

				function onErrorGeolocation() {
					const center = map.getCenter();
				}

				// customControl 객체 이용하기
				// 현재 위치 아이콘
				naver.maps.Event.once(map, "init_stylemap", function () {
					const customControl = new naver.maps.CustomControl(locationBtnHtml, {
						position: naver.maps.Position.BOTTOM_RIGHT,
					});
					customControl.setMap(map);

					const domEventListener = naver.maps.Event.addDOMListener(
						customControl.getElement(),
						"click",
						function () {						
							self.showLoader = true;
							if (navigator.geolocation) {
								navigator.geolocation.getCurrentPosition(
									onSuccessGeolocation,
									onErrorGeolocation
								);
							} else {
								const center = map.getCenter();
							}
						}
					);

					const customNewControl = new naver.maps.CustomControl(locationNewBtnHtml, {
						position: naver.maps.Position.BOTTOM_RIGHT,
					});
					customNewControl.setMap(map);

					const domNewEventListener = naver.maps.Event.addDOMListener(
						customNewControl.getElement(),
						"click",
						function () {			
							
							var noMark = document.getElementById('noMark'); 
							if ( noMark.style.display === 'none' ){ 
								noMark.style.display = 'block'; 
							} else { 
								noMark.style.display = 'none'; 
							}
							return;
						}
					);

					// 방문관리 페이지 접속 아이콘
					const customControl2 = new naver.maps.CustomControl(linkBtnHtml, {
						position: naver.maps.Position.BOTTOM_RIGHT,
					});
					customControl2.setMap(map);

					// 현재장소 버튼(빨간색)
					const customControl3 = new naver.maps.CustomControl(redMarker, {
						position: naver.maps.Position.RIGHT_CENTER,
					});
					customControl3.setMap(map);

					// 버튼을 클릭했을 때 나타나는 팝업 동작
					const domEventListener3 = naver.maps.Event.addDOMListener(
						customControl3.getElement(),
						"click",
						function () {
							$(".mapbridge").removeClass("on");
							$(".nodata_com").slideDown(200);
							//$(".green_company").slideUp(200);
							//$(".gray_company").slideUp(200);
							$(".nodata_com .close_btn").click(function () {
								$(".nodata_com").slideUp(200);
							});


							console.log("domEventListener3 > " );


							self.clickNoDataCompany()
						}
					);
				});
			}
        },
        mounted() {

			this.loadInit();
			
        },
        created() {
            //const res = await getOnly()
            //console.log(res)
			/*
            if(!("geolocation" in navigator)) {
				alert("false")
				return;
            }        
			*/

			console.log("created this.$route.query.lat  " + this.$route.params.lat );

			// if(this.$route.params.lat == undefined){

			// 	console.log("this.$route.params.lat false " + this.$route.params.lat);
			// 	this.noMark = true;
			// }else{
			// 	this.noMark = false;

			// 	console.log("this.$route.params.lat true " + this.$route.params.lat);
			// }

			
		}
    }
</script>
<style lang="scss" scoped>
    .btn-box {
        padding-top: 100px;
        text-align: center;
    }
</style>