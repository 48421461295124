import axios from 'axios'

const service = axios.create({
  baseURL: 'http://172.172.0.165',
  headers: {
    'Content-Type': 'application/json'
    
  },
  timeout: 30000 // request timeout
})

service.interceptors.request.use(
  config => {
    
    return config
  },
  error => {

    return Promise.reject(error)
  }
)


export default service
