<template>
<!-- 스플래시 화면 -->
    <div id="wrap">
			<div class="splash_area">
				<div class="splash">
					<p><img src="@/assets/drawable-xxxhdpi/splashy_logo.jpg"/></p>
				</div>
			</div>
			<div id="sp_footer">
				<p>Copyright © nlobby All rights reserved.</p>
			</div>
		</div>
</template>
<script>
export default {
    name: 'SplashScreen',
    mounted(){

		window.onpopstate = function(event) {
			console.log("location>>>: " + document.location + ", state: " + JSON.stringify(event.state));
		};
		
        setTimeout(() => {

			//앱 설치 후 바로 지도화면으로 (사용자 정보 입력화면 제거, 나중에 사용자가 필요하면 메뉴에서 수동으로 입력할수 있도록)
			//let tmpComponent = "VISITINGFORM";

			let tmpComponent = "HOME";

			// if(localStorage.getItem('name') != '' && localStorage.getItem('name') != null){
			// 	tmpComponent = "HOME";
			// }
            this.$router.push({name: tmpComponent})
        },2000)
    }
}
</script>