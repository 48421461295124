<template>
	<!-- 검색 화면 -->
	<div id="wrap">
		<div id="map" class="map_main">
			<div id="flow_search" class="flow_search scroll" v-if="showResultList == false">
				<div class="search_area">
					<div class="f_search">
						<button class="back_btn" id="back_btn" @click="didClickBack"> &#60; </button>
						<button class="txt_clear btn" @click="didClear()">지우기</button>
						<button id="submit" class="search_ico btn">주소 검색</button> <!-- @click="didClickSearch"-->
						<input id="searchbox" ref="searchbox" type="search" class="place_form" placeholder="주소 / 장소검색" v-model="keyword" @keyup="filter" >
							<ul id="ui-id-1" class="ui-menu ui-widget ui-widget-content ui-autocomplete highlight ui-front" 
							v-bind:class="{ show: isActive }" style=" width:100%;" v-show="showResultListData == true">
								<li class="search_list ui-menu-item"  v-for="(item,index) in result" :key="`view_list-key-${index}`" @click="didClickResult(index)">
									<div id="ui-id-4" class="ui-menu-item-wrapper">{{item.comp_name}}</div>
								</li>
							</ul>
					</div>
				</div>

			</div>
			<div id="flow_result" class="flow_result" v-else>
				<div class="result_top">
					<div class="f_result">
						<button class="back_btn" id="back_btn" @click="didClickBack"> &#60; </button>
						<p class="result_keyword">{{keyword}}</p>
						<button class="clear_btn btn" @click="didClear()">지우기</button>
						<input type="text" name="keyword" class="place_form" id="searchbox" placeholder="검색결과"/>
						
					</div>
				</div>
			</div>
			<SearchResultList :showResultList="showResultList" @closeResultList="closeResultList"></SearchResultList>
		</div>
	</div>
</template>
<script>
	import SearchResultList from './views/SearchResultList'
	export default {
		name: 'HomeSearch',
		components: {
			SearchResultList
		},
		data() {
			return {
				showResultList: false,
				showResultListData: true,
				keyword: '',
				compItems: this.$route.params.compItems,
				pgFlag: this.$route.params.pgFlag,
				result: null
			}
		},
		methods: {
			filter: function(){

				console.log("this.keyword : " +this.keyword);

				if(this.keyword.length >= 1){
					this.showResultListData = true;

					console.log("data.comp_name.length : " + this.compItems.length);

					this.result = this.compItems.filter(data => data.comp_name.match(new RegExp(this.keyword.replace(/ +(?= )/g, ''), "i")));
				}else if(this.keyword.length <= 0){
					this.showResultListData = false;
					this.result = null;
				}
				
			},
			didClickResult(index){


				console.log("this.pgFlag : " +this.pgFlag + " / " + index);

				if(this.pgFlag == "H"){

					//this.$parent.hiddenNoMark(false);

					this.$router.push({
						name: 'HOME',
						params: {lon: this.result[index].comp_lon, lat: this.result[index].comp_lat}
					})
					
				}else{
					this.$EventBus.$emit('parentSearchCompInfoBus', this.result[index])
					this.$router.go(-1);
				}
			},
			didClickSearch() {
				console.log('didClickSideMenu' + this.compItems.length)
				this.showResultList = true
			},
			closeResultList() {
				this.showResultList = false
			},
			didClickBack() {
				this.$router.go(-1)
			},
			didClear() {

				console.log(">>>>>");
				this.keyword = '';
				this.$refs.searchbox.focus();
			}
		},
		watch: {
			keyword(newVal, oldVal){

				if(newVal === ""){
					this.showResultListData = false;
					this.result = null;
				}
			}
		},
        mounted() {
			// 자동포커스
			this.$refs.searchbox.focus();
        }
	}
</script>