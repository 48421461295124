<template>
<!-- 임직원 등록 -->
    <div id="wrap">
			<header>
				<h1 class="title_line">임직원 등록</h1>
			</header>
			<div id="content">
				<div class="info_member">
					<h1 class="certify_title">임직원 인증</h1>
					<h2 class="certify_comment"><span class="green">방문예약시스템</span>을 이용중이신가요?</h2>
					<p class="black_txt">인증을 진행하시면 더욱 편하고 많은 기능을<br>이용하실 수 있습니다.
					<br><br>아래 버튼을 터치해서 인증을 진행 해 주세요.</p>
				</div>
				<div class="staff_area">
					<button id="staff_btn" class="green_line btn">임직원 인증</button>
				</div>
			</div>
		</div>
</template>
<script>
export default {
    name: 'MemberCertify'
}
</script>