<template>
    <!-- 지도 위치별 팝업 - 지역정보가 있는 경우-->
    <!-- 앤로비 고객사 / 앤로비 비 고객사-->
    <div>
        <div class="green_company company_pop"
            :class="{close : showMapCompany == false, open : showMapCompany == true}">
            <button class="close_btn" @click="closeMapCompany">X</button>
            <ul class="map_company_area scroll">
                <li v-for="(item,index) in mapCompItems" :key="`map-company-list-key${index}`">
                    <div class="pop_list_area">
                        <!--
                        <div class="list_img_area" v-show="isCustomer == true">
                            <div class="list_img">
                                <p><img :src="item.companyImgUrl" /></p>
                            </div>
                        </div>
                        -->
                        <div class="place_list">
                            <div class="list_title">
                                <h4 class="place_name">{{item.comp_name}}</h4>
                                <p class="place_address">{{item.comp_addr}}</p>
                                <div class="pop_btn">
                                    <div class="btn_ico">
                                        <button class="bookmark_ico" :class="{on : item.favorite == 'F'}" @click="clickBookMark(item.seq, item.favorite)">북마크 ICO</button>
                                        <button class="share_ico share-button" @click="clickMapShare">공유 ICO</button>
                                    </div>
                                    <div class="txt_btn">
                                        <p class="reserve_btn"><a @click="didVisitGoBtn(item.comp_url)">방문신청</a></p>
                                        <p class="modify_btn" v-if="item.comp_flag === N"><a @click="didClickBtn(item.seq)">수정</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <MapCompanyShare :showMapShare="showMapShare" @closeMapShare="closeMapShare" ref="MapCompanyShare"></MapCompanyShare>
    </div>
</template>
<script>
	import AlertModal from '../modals/AlertModal'
	
    import {
		setFavorite,
		delFavorite
	} from '@/api/common'
	
    import MapCompanyShare from './MapCompanyShare'
    export default {
        name: 'MapCompanyList',
        components: {
        MapCompanyShare
        },
        data() {
            return {
                isBookMark: false,
                showMapShare: false,
                shareCompItems : [],
            }
        },
        props: {
            showMapCompany: Boolean,
            isCustomer: Boolean,
            mapCompItems: Array
        },
        methods: {
            didClickBtn(seq){
                this.$router.push({name: 'EDITVISITPLACE', params: {mapCompItems: this.mapCompItems[0]}})
            },
            didVisitGoBtn(comp_url){

                console.log("comp_url :" + comp_url);
                try {
                    var message = {action: "visitGo", shareUrl: comp_url };
                    window['webkit'].messageHandlers['cordova_iab'].postMessage(JSON.stringify(message));
                } catch (err) {
                    window.open(comp_url, "_blank")
                }

            },
            clickBookMark(seq, favorite){

                let form = new FormData()
                form.append('seq', seq)
				form.append('tel', localStorage.getItem('tel'))
				
                if(favorite == "F"){

					delFavorite(form).then(response => {
						console.log(response)
						if(response.status == 200){

                            this.$emit('clickMapCompanyParentFunction',this.isCustomer)
							//this.$parent.clickMapCompany(this.isCustomer)
						}
					})
                }else{
                    setFavorite(form).then(response => {
                        console.log(response)
                        if(response.status == 200){
                            this.$emit('clickMapCompanyParentFunction',this.isCustomer)
                            //this.$parent.clickMapCompany(this.isCustomer)
                        }
                    })
                }
            },
            clickMapShare(){

                this.$refs.MapCompanyShare.shareInit(this.mapCompItems[0]);

                this.showMapShare = true
            },
            closeMapShare(){
                this.showMapShare = false
            },
            closeMapCompany() {
                this.$emit('closeMapCompany')
            },
        }
    }
</script>