<template>
    <div id="wrap">
        <div>
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Main',
}
</script>
<style>
</style>