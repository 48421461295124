<template>
<!-- 1:1문의 에서 문의내역 영역 -->
	<div>
		<div class="view_area">
			<div class="view_list">

				<div class="error_message" v-if="qnaItems.length <= 0">
					<p class="gray_txt">문의내역이 없습니다.</p>
				</div>

				<ul v-else>
					<li v-for="(item,index) in qnaItems" :key="`view_list-key-${index}`" @click="didClickList(index)"
						:class="{on: replyIndex == index}">
						<span class="ask">{{item.title}}<br><span class="ask_data">{{item.date}}</span><span
								class="green" v-if="item.re_content != null">답변완료</span><span class="green" v-else>확인
								중</span></span>
						<div class="reply">
							<p><span class="user_txt"><b style="font-weight: bold,margin-botton: 10px;font-weight:600">내가 작성한
										글</b><br>{{item.content}}</span>
								<span class="admin_txt" v-show="item.re_content != null"><b
										style="font-weight: bold,margin-botton: 10px;font-weight:600">관리자
										답변</b><br>{{item.re_content}}</span>
							</p>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
    import {
		getQnaList
    } from '@/api/common'

	export default {
		name: "FAQList",
		data() {
			return {
				replyIndex: -1,
				qnaItems: []
			}
		},
		methods: {
			didClickList(index) {
				if (this.replyIndex != index) {
					this.replyIndex = index
				} else {
					this.replyIndex = -1
				}
			}
		},
        created() {

            let form = new FormData()
            form.append('userKey', localStorage.getItem('tel'))
            
            getQnaList(form).then(response => {
                console.log(response)
                if(response.status == 200){

                	this.qnaItems = response.data.list;	
           		}
			})
        }
	}
</script>