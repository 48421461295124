<template>
	<!-- 검색 결과 모달 -->

	<div class="search_input company_pop" :class="{close : showResultList == false, open : showResultList == true}">
		<button class="close_btn" @click="closeResultList">X</button>
		<div class="tab_filter">
			<ul>
				<li :class="{on: selectIndex === 0}"><a @click="didClickMenu(0)">내위치 중심</a></li>
				<li :class="{on: selectIndex === 1}"><a @click="didClickMenu(1)">정확도 순</a></li>
			</ul>
		</div>
		<div class="result">
			<ul class="scroll" v-if="selectIndex === 0">
				<li v-for="(item,index) in items" :key="`Home-result1-list-key-${index}`">
					<a href="#">
						{{item.name}}<br>
						<span class="search_address">{{item.adress}}</span>
					</a>
				</li>
			</ul>
			<ul class="scroll" v-else-if="selectIndex === 1">
				<li v-for="(list,index) in lists" :key="`Home-result2-list-key-${index}`">
					<a href="#">
						{{list.name}}<br>
						<span class="search_address">{{list.adress}}</span>
					</a>
				</li>
			</ul>
		</div>
	</div>

</template>
<script>


	export default {
		name: 'SearchResultList',
		data() {
			return {
				selectIndex: 0,
				items: [{
						name: '엔로비 본사',
						adress: '부산광역시 해운대구 센텀동로 99'
					},
					{
						name: '엔로비 본사',
						adress: '부산광역시 해운대구 센텀동로 99'
					},
					{
						name: '엔로비 본사',
						adress: '부산광역시 해운대구 센텀동로 99'
					},
					{
						name: '엔로비 본사',
						adress: '부산광역시 해운대구 센텀동로 99'
					},
					{
						name: '엔로비 본사',
						adress: '부산광역시 해운대구 센텀동로 99'
					},
					{
						name: '엔로비 본사',
						adress: '부산광역시 해운대구 센텀동로 99'
					},
					{
						name: '엔로비 본사',
						adress: '부산광역시 해운대구 센텀동로 99'
					},
					{
						name: '엔로비 본사',
						adress: '부산광역시 해운대구 센텀동로 99'
					},
				],
				lists: [
					{
						name: '엔로비 본사',
						adress: '부산광역시 해운대구 센텀동로 99'
					},
					{
						name: '엔로비 본사',
						adress: '부산광역시 해운대구 센텀동로 99'
					},
					{
						name: '엔로비 본사',
						adress: '부산광역시 해운대구 센텀동로 99'
					},
					{
						name: '엔로비 본사',
						adress: '부산광역시 해운대구 센텀동로 99'
					},
					{
						name: '엔로비 본사',
						adress: '부산광역시 해운대구 센텀동로 99'
					},
					{
						name: '엔로비 본사',
						adress: '부산광역시 해운대구 센텀동로 99'
					},
					{
						name: '엔로비 본사',
						adress: '부산광역시 해운대구 센텀동로 99'
					},
					{
						name: '엔로비 본사',
						adress: '부산광역시 해운대구 센텀동로 99'
					},
				]
			}
		},
		props: {
			showResultList: Boolean
		},
		methods: {
			didClickMenu(idx){
				this.selectIndex = idx
			},
			closeResultList() {
				this.$emit('closeResultList')
				this.$router.push({name: "HOME"})
			}
		}
	}
</script>