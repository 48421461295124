import Vue from 'vue'
import Router from 'vue-router'
import Home from '../components/Home'
import Main from '../components/Main'
import VisitingForm from '../components/VisitingForm'
import MyPage from '../components/MyPage'
import CsCenter from '../components/CsCenter'
import QandA from '../components/QandA'
import HomeSearch from '../components/HomeSearch'
import MyPageEdit from '../components/MyPageEdit'
import AgreeTermDetail from '../components/AgreeTermDetail'
import SplashScreen from '../components/SplashScreen'
import CompanyInfo from '../components/CompanyInfo'
import CompanyLogin from '../components/CompanyLogin'
import AlarmListPage from '../components/AlarmListPage'
import SaveVisitPlace from '../components/SaveVisitPlace'
import EditVisitPlace from '../components/EditVisitPlace'
import MemberCertify from '../components/MemberCertify'
import CompanyFind from '../components/CompanyFind'
import VisitReserve from '../components/VisitReserve'
import VisitReserveDetail from '../components/VisitReserveDetail'

Vue.use(Router)

const routes = [{
    path: '/',
    redirect: {
        name: 'SPLASHSCREEN',
    },    
},
{
    path: '/main',
    name: 'MAIN',
    component: Main,
    children: [
        {
            path: 'splashscreen',
            name: 'SPLASHSCREEN',
            component: SplashScreen
        },
        {
            path: 'companylogin',
            name: 'COMPANYLOGIN',
            component: CompanyLogin
        },
        {
            path: 'home',
            name: 'HOME',
            component: Home,
            props: true
        },
        {
            path: 'visitingform',
            name: 'VISITINGFORM',
            component: VisitingForm
        },
        {
            path: 'mypage',
            name: 'MYPAGE',
            component: MyPage
        },
        {
            path: 'mypageedit',
            name: 'MYPAGEEDIT',
            component: MyPageEdit,
            props: true
        },
        {
            path: 'cscenter',
            name: 'CSCENTER',
            component: CsCenter
        },
        {
            path: 'qanda',
            name: 'QANDA',
            component: QandA
        },
        {
            path: 'homesearch',
            name: 'HOMESEARCH',
            component: HomeSearch
        },
        {
            path: 'agreetermdetail/:termId',
            name: 'AGREETERMDETAIL',
            component: AgreeTermDetail,
            props: true
        },
        {
            path: 'companyinfo',
            name: 'COMPANYINFO',
            component: CompanyInfo
        },
        {
            path: 'companyfind',
            name: 'COMPANYFIND',
            component: CompanyFind
        },
        {
            path: 'alarmlistpage',
            name: 'ALARMLISTPAGE',
            component: AlarmListPage
        },
        {
            path: 'savevisitPlace',
            name: 'SAVEVISITPLACE',
            component: SaveVisitPlace
        },
        {
            path: 'editvisitPlace',
            name: 'EDITVISITPLACE',
            component: EditVisitPlace,
            props: true
        },
        {
            path: 'membercertify',
            name: 'MEMBERCERTIFY',
            component: MemberCertify
        },
        {
            path: 'visitreserve',
            name: 'VISITRESERVE',
            component: VisitReserve
        },
        {
            path: 'visitreserveDetail/:reserveId',
            name: 'VISITRESERVEDETAIL',
            component: VisitReserveDetail
        }
    ]
}
]

const createRouter = () => new Router({
    mode: 'history', // require service support
    routes,
    // scrollBehavior() {
    //     document.getElementById('app').scrollIntoView();
    // },
})
const router = createRouter()
// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router