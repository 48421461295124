import request from '@/utils/request'

//const appServer = 'http://127.0.0.1'

const appServer = 'https://nlobbyapp.com/'

export function getOnly() {
    return request({
        url: '/get',
        method: 'get',
    })
}

export function getWithParam(param) {
    return request({
        url: '/param',
        method: 'get',
        params: {
            'param': param
        }
    })
}

export function postWithData(data) {
    return request({
        url: '/postWithData',
        method: 'post',
        data
    })
}

export function getCompInfoList(data) {
    return request({
        url: appServer + '/json/compInfo/compInfoList/RL',
        method: 'post',
        data
    })
}

export function getBottomCompList(data) {
    return request({
        url: appServer + '/json/compInfo/bottomCompList/RL',
        method: 'post',
        data
    })
}

export function getFavoriteList(data) {
    return request({
        url: appServer + '/json/compInfo/favoriteList/RL',
        method: 'post',
        data
    })
}

export function setFavorite(data) {

    console.log("data : " +JSON.stringify(data));
    return request({
        url: appServer + '/json/compInfo/favorite/CUDO?strQry=insert',
        method: 'post',
        data
    })
}

export function delFavorite(data) {

    console.log("data : " +JSON.stringify(data));
    return request({
        url: appServer + '/json/compInfo/favorite/CUDO?strQry=delete',
        method: 'post',
        data
    })
}

export function getCompList(data) {
    return request({
        url: appServer + '/json/compInfo/compList/RL',
        method: 'post',
        data
    })
}

export function getRecentSearchHistory(data) {
    return request({
        url: appServer + '/json/compInfo/recentSearchHistory/RL',
        method: 'post',
        data
    })
}

export function getQnaList(data) {
    return request({
        url: appServer + '/json/qna/qnaList/RL',
        method: 'post',
        data
    })
}

export function getComCodeList(data) {
    return request({
        url: appServer + '/json/common/comCodeList/RL',
        method: 'post',
        data
    })
}

export function fileUpload(data) {
    return request({
        url: appServer + '/upload/fileUpload',
        method: 'post',
        headers: { 'Content-Type': 'multipart/form-data' },
        data
    })
}

export function saveQna(data) {

    console.log("data : " +JSON.stringify(data));
    return request({
        url: appServer + '/saveQna',
        method: 'post',
        data
    })
}

export function saveVisitPlace(data) {

    console.log("data : " +JSON.stringify(data));
    return request({
        url: appServer + '/json/compInfo/visitPlace/CUDO?strQry=insert',
        method: 'post',
        data
    })
}

export function updateVisitPlace(data) {

    console.log("data : " +JSON.stringify(data));
    return request({
        url: appServer + '/json/compInfo/visitPlace/CUDO?strQry=update',
        method: 'post',
        data
    })
}