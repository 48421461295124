<template>
<!-- 고객센터 -->
		<div id="wrap">
			<header>
				<h1 class="title_line"><a @click="didClickBack"> &#60; </a>고객센터</h1>
			</header>
			<div id="content">
				<div class="cs_area">
					<h1>궁금하신 내용이 있다면<br><span class="green">고객센터</span>에서 도와드릴게요.</h1>
					<div class="cs_list">
						<ul>
							<li v-for="(item,index) in items" :key="`cs-list-item-key-${index}`"
								@click="didClickQuetion(index)" :class="{on : replyIndex  == index}"><span
									class="ask">{{item.ask}}</span>
								<div class="reply">
									<p v-html="item.answer">
									</p>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div id="inquiry_btn">
				<button class="green_bg btn" @click="didClickQandA">1:1문의</button>
			</div>
		</div>	
</template>
<script>
	export default {
		name: 'CsCenter',
		data() {
			return {
				replyIndex: -1,
				items: [{
						ask: '자주 방문하는 장소를 등록하고 싶어요.',
						answer: '방문신청은 엔로비 앱 메인화면에서 현재 나의 위치 중심으로 가까운 곳을 찾아서 신청하거나 또는 직접 찾으시는 방문사를 검색해서 찾은 뒤 방문신청이 가능하며, 방문신청 이후의 진행상황은 왼쪽 상단버튼을 누르고 <b>방문예약확인</b>을 누르시면 확인하실 수 있습니다.'
					},
					{
						ask: '방문신청과 진행상황을 알고 싶어요.',
						answer: '방문신청은 엔로비 앱 메인화면에서 현재 나의 위치 중심으로 가까운 곳을 찾아서 신청하거나 또는 직접 찾으시는 방문사를 검색해서 찾은 뒤 방문신청이 가능하며, 방문신청 이후의 진행상황은 왼쪽 상단버튼을 누르고 <b>방문예약확인</b>을 누르시면 확인하실 수 있습니다.'
					},
					{
						ask: '자주 방문하는 장소를 등록하고 싶어요.',
						answer: '방문신청은 엔로비 앱 메인화면에서 현재 나의 위치 중심으로 가까운 곳을 찾아서 신청하거나 또는 직접 찾으시는 방문사를 검색해서 찾은 뒤 방문신청이 가능하며, 방문신청 이후의 진행상황은 왼쪽 상단버튼을 누르고 <b>방문예약확인</b>을 누르시면 확인하실 수 있습니다.'
					},
					{
						ask: '방문신청과 진행상황을 알고 싶어요.',
						answer: '방문신청은 엔로비 앱 메인화면에서 현재 나의 위치 중심으로 가까운 곳을 찾아서 신청하거나 또는 직접 찾으시는 방문사를 검색해서 찾은 뒤 방문신청이 가능하며, 방문신청 이후의 진행상황은 왼쪽 상단버튼을 누르고 <b>방문예약확인</b>을 누르시면 확인하실 수 있습니다.'
					},
					{
						ask: '자주 방문하는 장소를 등록하고 싶어요.',
						answer: '방문신청은 엔로비 앱 메인화면에서 현재 나의 위치 중심으로 가까운 곳을 찾아서 신청하거나 또는 직접 찾으시는 방문사를 검색해서 찾은 뒤 방문신청이 가능하며, 방문신청 이후의 진행상황은 왼쪽 상단버튼을 누르고 <b>방문예약확인</b>을 누르시면 확인하실 수 있습니다.'
					},
					{
						ask: '방문신청과 진행상황을 알고 싶어요.',
						answer: '방문신청은 엔로비 앱 메인화면에서 현재 나의 위치 중심으로 가까운 곳을 찾아서 신청하거나 또는 직접 찾으시는 방문사를 검색해서 찾은 뒤 방문신청이 가능하며, 방문신청 이후의 진행상황은 왼쪽 상단버튼을 누르고 <b>방문예약확인</b>을 누르시면 확인하실 수 있습니다.'
					},
					{
						ask: '자주 방문하는 장소를 등록하고 싶어요.',
						answer: '방문신청은 엔로비 앱 메인화면에서 현재 나의 위치 중심으로 가까운 곳을 찾아서 신청하거나 또는 직접 찾으시는 방문사를 검색해서 찾은 뒤 방문신청이 가능하며, 방문신청 이후의 진행상황은 왼쪽 상단버튼을 누르고 <b>방문예약확인</b>을 누르시면 확인하실 수 있습니다.'
					},
					{
						ask: '방문신청과 진행상황을 알고 싶어요.',
						answer: '방문신청은 엔로비 앱 메인화면에서 현재 나의 위치 중심으로 가까운 곳을 찾아서 신청하거나 또는 직접 찾으시는 방문사를 검색해서 찾은 뒤 방문신청이 가능하며, 방문신청 이후의 진행상황은 왼쪽 상단버튼을 누르고 <b>방문예약확인</b>을 누르시면 확인하실 수 있습니다.'
					},
				]
			}
		},
		methods: {
			didClickQuetion(index) {
				if (this.replyIndex != index) {
					this.replyIndex = index
				} else {
					this.replyIndex = -1
				}

			},
			didClickBack() {
				this.$router.go(-1)
			},
			didClickQandA() {
				this.$router.push({
					name: "QANDA"
				})
			}
		}
	}
</script>