<template>
<!-- 버튼이 하나인 알림메뉴 -->
    <div class="alertmodal">
        <p v-html="content"></p>
        <button class="close-modal-btn green_bg" @click="clickCloseModal">확인</button>
    </div>
</template>
<script>
    export default {
        name: 'AlertModal',
        props: {
            content: String,
            goHome:Boolean,
            goBack: Boolean
        },
        methods: {
            clickCloseModal() {
                if(this.goHome == true){
                    this.$modal.hide('AlertModal')
                    this.$router.push({name: 'HOME'})
                }else if(this.goBack == true){
                    this.$modal.hide('AlertModal')
                    this.$router.go(-1)
                }else {
                    this.$modal.hide('AlertModal')
                }
                
            }

        }
    }
</script>
<style lang="scss" scoped>
    .alertmodal {
        text-align: center;
        position: relative;

        p {
            padding: 20px;
            box-sizing: border-box;
        }

        .close-modal-btn {
            width: 100%;
            height: 50px;
            border: none;
        }
    }
</style>