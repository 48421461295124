<template>
<!-- 버튼이 두개인 알림 메뉴 -->
    <div class="confirm-modal">
        <h4 class="confirm-text" v-html="content"></h4>
        <div class="btn-box">
            <button class="confirm-btn" @click="didClickCancle">취소</button>
            <button class="confirm-btn green_bg" @click="didClickConfirm">확인</button>
        </div>

    </div>
</template>
<script>
    export default {
        name: 'ConfirmModal',
        props: {
            content: String,
            goHome:Boolean,
            goBack: Boolean
        },
        methods: {
            didClickConfirm(){

                this.$modal.hideAll()

                if(this.goHome == true){
                    this.$router.push({name: 'HOME'})
                }else if(this.goBack == true){
                    this.$router.go(-1)
                }else {
                    localStorage.setItem('name', '');
                    localStorage.setItem('company', '');
                    localStorage.setItem('position', '');
                    localStorage.setItem('carNumber', '');
                    localStorage.setItem('email', '');
                    localStorage.setItem('tel', '');

                    
                    this.$router.push({name: 'VISITINGFORM'})
                }


            },
            didClickCancle() {
                this.$modal.hideAll()
            }
        }
    }
</script>
<style lang="scss" scoped>
.confirm-modal{
    text-align: center;
    .confirm-text {
        padding: 20px;
        line-height: 20px;
    }
    .btn-box {
        width: 100%;
        height: 50px;
        display: flex;
        flex-direction: row;
        .confirm-btn {
            width: 50%;
            height: 100%;
            border: none;
        }
    }
}
</style>