<template>
<!-- 방문예약 확인 -->
    <div id="wrap">
        <header>
            <h1 class="title_line"><a @click="didClickBack">
                    &#60; </a>방문예약 확인</h1>
        </header>
        <div id="content">
            <div class="reserve_list">
                <ul v-if="items.length > 0">
                    <li v-for="(item,index) in items" :key="`reserve-list-key-${index}`" @click="clickReserveCard(index)">
                        <div class="company_logo">
                            <a href="#" class="reserve_link"><img :src="item.itemImgUrl" alt="logo" /></a>
                        </div>
                        <div class="reserve_info">
                            <a href="#" class="reserve_link">
                                <h4 class="reserve_name">{{item.itemName}}</h4>
                                <p class="reserve_address">{{item.itemAdress}}</p>
                                <div class="check_state">
                                    <p class="state in"><b>IN</b>{{item.itemDate}} / {{item.itemTime}}</p>
                                    <p class="state out"><b>OUT</b>{{item.itemDate}} / {{item.itemTime}}</p>
                                </div>
                                <p class="reserve_result end">종료</p>
                            </a>
                        </div>

                    </li>
                </ul>
                <div class="error_message" v-else>
                    <p class="gray_txt">방문신청한 내역이 없습니다.</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'VisitReserve',
        data() {
            return {
                items: [
                    {
                    itemImgUrl: require('@/assets/drawable-xxxhdpi/company_ico.png'),
                    itemName: 'GS건설 본사',
                    itemAdress: '서울특별시 강남구 논현로 508 GS타워',
                    itemDate: '21. 01. 25',
                    itemTime: '10:30'
                },
                {
                    itemImgUrl: require('@/assets/drawable-xxxhdpi/company_ico.png'),
                    itemName: 'GS건설 본사',
                    itemAdress: '서울특별시 강남구 논현로 508 GS타워',
                    itemDate: '21. 01. 25',
                    itemTime: '10:30'
                },
                {
                    itemImgUrl: require('@/assets/drawable-xxxhdpi/company_ico.png'),
                    itemName: 'GS건설 본사',
                    itemAdress: '서울특별시 강남구 논현로 508 GS타워',
                    itemDate: '21. 01. 25',
                    itemTime: '10:30'
                },
                {
                    itemImgUrl: require('@/assets/drawable-xxxhdpi/company_ico.png'),
                    itemName: 'GS건설 본사',
                    itemAdress: '서울특별시 강남구 논현로 508 GS타워',
                    itemDate: '21. 01. 25',
                    itemTime: '10:30'
                },
                {
                    itemImgUrl: require('@/assets/drawable-xxxhdpi/company_ico.png'),
                    itemName: 'GS건설 본사',
                    itemAdress: '서울특별시 강남구 논현로 508 GS타워',
                    itemDate: '21. 01. 25',
                    itemTime: '10:30'
                },
                ]
            }
        },
        methods: {
            clickReserveCard(index){
                this.$router.push({ name: 'VISITRESERVEDETAIL', params: {
                    reserveId: index
                }})
            },
            didClickBack(){
                this.$router.go(-1)
            }
        }
    }
</script>