<template>
<!-- 알람리스트 페이지 -->
    <div id="wrap">
        <div class="alarm_area">
            <div class="alarm_header">
                <h1 class="title_line"><a class="back_link" @click="didClickBack"> &#60; </a>알림</h1>
            </div>
            <div class="alarm_content">
                <ul>
                    <li class="alarm" v-for="(item,index) in items" :key="`alarm-list-key-${index}`">
                        <a>
                            <span class="data">{{item.date}} / {{item.time}}</span>
                            <span class="alarm_txt">{{item.userName}}님 <b class="green">{{item.adressName}}</b>
                                방문예약되었습니다.
                                자세한 내용은 터치해서 확인 해 주세요.</span>
                        </a>
                        <button class="alarm_del">알람삭제</button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'AlarmListPage',
        data() {
            return {
                items: [{
                        date: '2021.01.25',
                        time: '09:00',
                        userName: '이민호',
                        adressName: 'GS칼텍스'
                    },
                    {
                        date: '2021.01.25',
                        time: '09:00',
                        userName: '이민호',
                        adressName: 'GS칼텍스'
                    },
                    {
                        date: '2021.01.25',
                        time: '09:00',
                        userName: '이민호',
                        adressName: 'GS칼텍스'
                    },
                    {
                        date: '2021.01.25',
                        time: '09:00',
                        userName: '이민호',
                        adressName: 'GS칼텍스'
                    },
                    {
                        date: '2021.01.25',
                        time: '09:00',
                        userName: '이민호',
                        adressName: 'GS칼텍스'
                    },
                ]
            }
        },
        methods: {
            didClickBack(){
                this.$router.go(-1)
            }
        }
    }
</script>