<template>
    <!-- 방문신청 정보 작성 -->
    <div id="wrap">
        <header>
            <h1 class="title_line">방문신청 정보 작성</h1>
        </header>
        <div class="NL-VF-content" v-if="showContent == 0">
            <div class="gray_bg">
                <div class="agreement_area">
                    <div class="agreement">
                        <h2 class="header_line">이용약관 동의</h2>
                        <div class="check_box">
                            <button class="con_btn" @click="clickAllChecked"
                                :class="{checked: checkTerm1 == true && checkTerm2 == true }">전체동의</button>
                            <div class="check_form">
                                <div class="check_form_term">
                                    <input type="checkbox" id="provision1" name="check_input" class="check_input"
                                        v-model="checkTerm1">
                                    <label for="provision1">엔로비 이용약관 동의 (필수)</label>
                                    <a class="termDetail" @click="didClickTerm(0,'이용약관')">
                                        <unicon name="angle-right" class="unicons"></unicon>
                                    </a>
                                </div>
                                <div class="check_form_term">
                                    <input type="checkbox" id="provision2" name="check_input" class="check_input"
                                        v-model="checkTerm2">
                                    <label for="provision2">엔로비 개인정보 처리방침 (필수)</label>
                                    <a class="termDetail" @click="didClickTerm(1,'개인정보 처리방침')">
                                        <unicon name="angle-right" class="unicons"></unicon>
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="apply_area">
                <div class="apply_info">
                    <h2 class="header_line">방문정보 작성</h2>
                    <p class="sub_title">엔로비 이용고객사에 방문신청 하실 경우 자동으로 입력됩니다.<br>
                        <span class="t_mini">입력하신 정보는 스마트폰에 저장되며 서버로 전송되지 않습니다.</span>
                    </p>
                    <div class="apply_form">
                        <form name="frm1">
                            <label class="red_form form_label name_label">
                                <span class="form_title">이름</span>
                                <input type="text" name="name" class="name form" placeholder="이름 입력" v-model="name" />
                                <button class="txt_clear btn" @click="name = ''">지우기</button>
                            </label>
                            <p class="error_m name_error">이름을 입력해주세요.</p>
                            <label class="red_form form_label company_label">
                                <span class="form_title">소속회사</span>
                                <input type="text" name="company" class="company form" placeholder="소속회사 입력" required
                                    v-model="company" />
                                <button class="txt_clear btn" @click="company = ''">지우기</button>
                            </label>
                            <p class="error_m company_error">소속회사를 입력해주세요.</p>
                            <label class="red_form form_label duty_label">
                                <span class="form_title">직책</span>
                                <input type="text" name="duty" class="duty form" placeholder="예) 사원 / 주임 / 대리 / 과장"
                                    required v-model="position" />
                                <button class="txt_clear btn" @click="position = ''">지우기</button>
                            </label>
                            <p class="error_m duty_error">직책을 입력해주세요.</p>
                            <label class="red_form form_label phone_label">
                                <span class="form_title">휴대전화번호</span>
                                <input type="tel" name="phone" class="phone form" placeholder="예) 01012341234" required
                                    v-model="tel" />
                                <button class="txt_clear btn" @click="tel = ''">지우기</button>
                            </label>
                            <p class="error_m phone_error">휴대전화번호를 입력해주세요.</p>
                            <label class="free_form form_label car_label">
                                <span class="form_title">차량번호</span>
                                <input type="text" name="car_num" class="car_num form" placeholder="예) 123가1234"
                                    v-model="carNumber" />
                                <button class="txt_clear btn" @click="carNumber = '' ">지우기</button>
                            </label>
                            <label class="free_form form_label">
                                <span class="form_title">이메일</span>
                                <input type="email" name="email" class="email form" placeholder="예) abc@abcdefg.com"
                                    v-model="email" />
                                <button class="txt_clear btn" @click="email = ''">지우기</button>
                            </label>
                        </form>
                    </div>
                </div>
            </div>
            <div id="footer_btn">
                <button id="complet_btn" class="complete_btn" @click="didClickCompleted"
                    :class="{checked: checkTerm1 == true && checkTerm2 == true && name.length > 0 && company.length > 0 && position.length > 0 && tel.length > 0}">작성완료</button>
            </div>
        </div>
        <div class="NL-VF-content" v-else>
            <div class="info_complete">
                <h1 class="comple_title">방문신청 정보<br><b>작성완료</b></h1>
                <p class="black_txt">엔로비 방문신청 정보 작성이 완료되었습니다.<br>지금부터 엔로비앱 사용이 가능합니다.</p>

                <!-- <p class="gray_txt">
                    <span class="green">엔로비 방문관리 고객사</span>
                    일 경우<br>아래 버튼에서 임직원 인증을 진행 해주세요.<br><br>더욱 편하고 많은 기능을 이용하실 수 있습니다.
                </p> -->

            </div>
            <div class="staff_area">
                <!-- <button onclick="location.href='company_info.html'" id="staff_btn" class="green_line btn">임직원 인증</button>
					<button onclick="location.href='map.html'" id="main_btn" class="green_bg btn">나중에 하기</button> -->
                <button id="main_btn" class="green_bg btn" @click="didClickHome">엔로비 앱 시작</button>


                <!-- <p class="sblack_txt">인증은 <span class="green">메인페이지 좌측 상단</span> 메뉴에서<br>등록 가능합니다.</p> -->

            </div>
        </div>
    </div>
</template>
<script>
    import AlertModal from './modals/AlertModal'
    import axios from 'axios'
    export default {
        name: 'VisitingForm',
        data() {
            return {
                name: '',
                company: '',
                position: '',
                tel: '',
                carNumber: '',
                email: '',
                showContent: 0,
                checkTerm1: false,
                checkTerm2: false,
                showError: false
            }
        },
        methods: {
            didClickHome() {
                this.$router.push({
                    name: 'HOME'
                })
            },
            didClickCompleted() {
                if (this.checkTerm1 == true && this.checkTerm2 == true && this.name.length > 0 && this.company.length >
                    0 && this.position.length > 0 && this.tel.length > 0) {

                    localStorage.setItem('name', this.name);
                    localStorage.setItem('company', this.company);
                    localStorage.setItem('position', this.position);
                    localStorage.setItem('carNumber', this.carNumber);
                    localStorage.setItem('email', this.email);
                    localStorage.setItem('tel', this.tel);

                    this.showContent = 1
                } else if (this.checkTerm1 == true && this.checkTerm2 == true && this.name.length <= 0 || this.company
                    .length <= 0 || this.position.length <= 0 || this.tel.length <= 0) {
                    this.$modal.show(AlertModal, {
                        content: '필수 방문정보를 모두 입력하세요.',
                        goHome: false,
                        goBack: false
                    }, {
                        name: 'AlertModal'
                    })
                    return false;
                } else if (this.checkTerm1 == false || this.checkTerm2 == false && this.name.length > 0 && this.company
                    .length > 0 && this.position.length > 0 && this.tel.length > 0) {
                    this.$modal.show(AlertModal, {
                        content: '이용약관을 모두 동의해주세요.',
                        goHome: false,
                        goBack: false
                    }, {
                        name: 'AlertModal'
                    })
                    return false;
                } else {
                    this.$modal.show(AlertModal, {
                        content: '필수 정보를 모두 입력해주세요.',
                        goHome: false,
                        goBack: false
                    }, {
                        name: 'AlertModal'
                    })
                    return false;
                }
            },
            clickAllChecked() {
                if (this.checkTerm1 == true && this.checkTerm2 == true) {
                    this.checkTerm1 = false
                    this.checkTerm2 = false
                } else {
                    this.checkTerm1 = true
                    this.checkTerm2 = true
                }

            },
            didClickTerm(termId, title) {
                console.log('didClickTerm')
                this.$router.push({
                    name: 'AGREETERMDETAIL',
                    params: {
                        termId,
                        title
                    }
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    .NL-VF-content {
        padding-top: 60px;
        overflow-y: scroll !important;
    }

    .check_form_term {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;

        .termDetail {
            width: 32px;
            height: 32px;
            z-index: 9999 !important;
        }
    }



    .unicons {
        position: absolute;
        top: 4px;
        right: 0;
    }
</style>