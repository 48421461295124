<template>
<!-- 공유하기 모달 -->
    <div class="share-dialog" :class="{close : showMapShare == false, open : showMapShare == true}">
				<div class="share_title">
					<h3 class="dialog-title">공유하기</h3>
					<button class="close_btn"  @click="closeMapShare"><a>X</a></button>
				</div>
				<div class="targets">					
					<a class="sns_btn sms_ico" @click="didClickSms">
						<span class="sns_name" >메시지</span>
					</a>
					<a class="sns_btn kakao_ico" @click="didClickKakao">
						<span class="sns_name">카카오톡</span>
					</a>
					<a class="sns_btn email_ico" @click="didClickEmail">
						<span class="sns_name" >Email</span>
					</a>
					<a class="sns_btn more_ico"  @click="didClickShare">
						<span class="sns_name">더 보기</span>
					</a>
				</div>
				<div class="link">
					<input type="text" class="pen-url" v-model="urlInput" readonly/>
					<button class="copy-link" @click="didClickCopy">링크복사</button>
				</div>
			</div>
</template>
<script>
import AlertModal from '../modals/AlertModal'
export default {
    name: 'MapCompanyShare',
    props: {
        showMapShare: Boolean
    },
	data() {
		return {
			urlInput: '',
			compObj : ''
		}
	},
    methods: {

		shareInit(obj){
			this.urlInput = obj.comp_url;
			this.compObj = obj;
		},
		didClickCopy(){

			this.$copyText(this.urlInput);

			this.$modal.show(AlertModal, {
				content: '링크가 복사되었습니다. 원하는 곳에 붙여넣기 하세요.',
				goHome: false,
				goBack: false
			}, {
				name: 'AlertModal'
			})

		},
		didClickKakao(){

		    let tmpUrl = "";

			let tmpMsg = '"' + this.compObj.comp_name + '"' + '에 방문하시기 전 미리 신청하시면 대기없이 입장하실수 있습니다.\n';

			tmpMsg += '\n회사 : ' + this.compObj.comp_name;
			tmpMsg += '\n주소 : ' + this.compObj.comp_addr;
			tmpMsg += '\n방문신청 : ' + this.compObj.comp_url;

            const varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

            console.log("varUA : " +varUA + " / " + varUA.toLowerCase().indexOf('android'));

            if ( varUA.toLowerCase().indexOf('android') > -1) {
                tmpMsg += '\n\n구글(안드로이드)플레이 스토어 설치 페이지 링크 : https://play.google.com/store/apps/details?id=com.nlobby.visit';

                tmpUrl = "https://play.google.com/store/apps/details?id=com.nlobby.visit";

            } else if ( varUA.indexOf("iphone") > -1||varUA.indexOf("ipad") > -1||varUA.indexOf("ipod") > -1 ) {
                tmpMsg += '\n\n애플(IOS) 스토어 설치 페이지 링크 : https://apps.apple.com/kr/app/id6444391758';

                tmpUrl = "https://apps.apple.com/kr/app/id6444391758";

            }

            Kakao.Link.sendDefault({
                objectType: 'feed',
                content: {
                    title: '방문신청은 비즈잇',
                    description: tmpMsg,
                    imageUrl:
                        'https://play-lh.googleusercontent.com/JSCm-VWq4-ZUIaSOkf3SQqWPjK_IXTJ19qM0L8SKN8kD4q5wFEaFNMZVcYv2V6yaaEo=w416-h235-rw',
                    link: {
                        mobileWebUrl: tmpUrl,
                        webUrl: tmpUrl,
                    },
                },
                buttons: [
                    {
                        title: '설치하기',
                        link: {
                            mobileWebUrl: tmpUrl,
                            webUrl: tmpUrl,
                        },
                    },
                ],
            })
		},
		didClickShare(){


			let tmpMsg = '"' + this.compObj.comp_name + '"' + '에 방문하시기 전 미리 신청하시면 대기없이 입장하실수 있습니다.\n';

			tmpMsg += '\n회사 : ' + this.compObj.comp_name;
			tmpMsg += '\n주소 : ' + this.compObj.comp_addr;
			tmpMsg += '\n방문신청 : ' + this.compObj.comp_url;

            const varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

            if ( varUA.indexOf('android') > -1) {
                tmpMsg += '\n\n구글(안드로이드)플레이 스토어 설치 페이지 링크 : https://play.google.com/store/apps/details?id=com.nlobby.visit';

            } else if ( varUA.indexOf("iphone") > -1||varUA.indexOf("ipad") > -1||varUA.indexOf("ipod") > -1 ) {
                tmpMsg += '\n\n애플(IOS) 스토어 설치 페이지 링크 : https://apps.apple.com/kr/app/id6444391758';

            }

			var message =   {action: "share", 
							subject: "방문신청은 비즈잇",
							message: tmpMsg,
							shareUrl: this.urlInput };
			
			
			window['webkit'].messageHandlers['cordova_iab'].postMessage(JSON.stringify(message));
		},
        closeMapShare(){
            this.$emit('closeMapShare')
        },
		didClickSms(){

			const varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

			var m;


			//let 은 변수에 재할당이 가능하다. const는 변수 재선언, 변수 재할당 모두 불가능하다. (변수 재선언이 되지 않는다)
			let tmpMsg = '"' + this.compObj.comp_name + '"' + '에 방문하시기 전 미리 신청하시면 대기없이 입장하실수 있습니다.\n';

			tmpMsg += '\n회사 : ' + this.compObj.comp_name;
			tmpMsg += '\n주소 : ' + this.compObj.comp_addr;
			tmpMsg += '\n방문신청 : ' + this.compObj.comp_url;

			if ( varUA.indexOf('android') > -1) {
				//안드로이드
				m = "android";
				tmpMsg += '\n\n구글(안드로이드)플레이 스토어 설치 페이지 링크 : https://play.google.com/store/apps/details?id=com.nlobby.visit';
			} else if ( varUA.indexOf("iphone") > -1||varUA.indexOf("ipad") > -1||varUA.indexOf("ipod") > -1 ) {
				//IOS
				m = "ios";
				tmpMsg += '\n\n애플(IOS) 스토어 설치 페이지 링크 : https://apps.apple.com/kr/app/id6444391758';
			} else {
				//아이폰, 안드로이드 외
				m = "other";
			}

            if ( varUA.indexOf('android') > -1) {
                location.href = 'sms:' +''+(m == 'ios' ? '&' : '?') + 'body='+ encodeURIComponent(tmpMsg);

            } else if ( varUA.indexOf("iphone") > -1||varUA.indexOf("ipad") > -1||varUA.indexOf("ipod") > -1 ) {

                var message =   {action: "tel",
                                message: tmpMsg};

                window['webkit'].messageHandlers['cordova_iab'].postMessage(JSON.stringify(message));
            }

		},
		didClickEmail(){

			const varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

			var m;

			let tmpMsg = '"' + this.compObj.comp_name + '"' + '에 방문하시기 전 미리 신청하시면 대기없이 입장하실수 있습니다.\n';

			tmpMsg += '\n회사 : ' + this.compObj.comp_name;
			tmpMsg += '\n주소 : ' + this.compObj.comp_addr;
			tmpMsg += '\n방문신청 : ' + this.compObj.comp_url;

			if ( varUA.indexOf('android') > -1) {
				//안드로이드
				m = "android";
				tmpMsg += '\n\n구글(안드로이드)플레이 스토어 설치 페이지 링크 : https://play.google.com/store/apps/details?id=com.nlobby.visit';
			} else if ( varUA.indexOf("iphone") > -1||varUA.indexOf("ipad") > -1||varUA.indexOf("ipod") > -1 ) {
				//IOS
				m = "ios";
				tmpMsg += '\n\n애플(IOS) 스토어 설치 페이지 링크 : https://apps.apple.com/kr/app/id6444391758';
			} else {
				//아이폰, 안드로이드 외
				m = "other";
			}

            if ( varUA.indexOf('android') > -1) {
                location.href='mailto:' + '?' +(m == 'ios' ? '&' : '?')+ '&body=' + encodeURIComponent(tmpMsg);

            } else if ( varUA.indexOf("iphone") > -1||varUA.indexOf("ipad") > -1||varUA.indexOf("ipod") > -1 ) {

                var message =   {action: "mail",
                                message: tmpMsg};

                window['webkit'].messageHandlers['cordova_iab'].postMessage(JSON.stringify(message));
            }
		},
    }

}
</script>