<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'App',
  }
</script>
<style>
body {
  margin: 0px !important;
  
}

html,body {
  font-family: 'Noto Sans KR' !important;
}
</style>
<style lang="scss">
@import url('~@/styles/_common.scss');
@import url('~@/styles/_normalize.scss');
@import url('~@/styles/_reset.scss');
// @import url('~@/styles/_font.scss');
// @import url('~@/styles/_line.scss');
// @import url('~@/styles/_unicons.scss');

</style>