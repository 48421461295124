<template>
<!-- 방문예약 상세 -->
    <div id="wrap">
        <header>
            <h1 class="title_line"><a @click="didClickBack"> &#60; </a>방문예약 상세내용</h1>
        </header>
        <div id="content">
            <div class="gray_bg">
                <div class="reserve_detail">
                    <div class="reserve_state">
                        <h2 class="header_line">방문예약 현황</h2>
                        <div class="step_list">
                            <div class="step step1">
                                <p><img src="@/assets/drawable-xxxhdpi/apply.png" />방문신청</p>
                            </div>
                            <!--
								<div class="step step1 on">
									<p><img src="../img/drawable-xxxhdpi/apply_on.png"/>방문신청</p>
								</div>
								-->
                            <!--
								<div class="step step2">
									<p><img src="../img/drawable-xxxhdpi/approve.png"/>승인 / 반려</p>
								</div>
								-->
                            <div class="step step2 on">
                                <p><img src="@/assets/drawable-xxxhdpi/approve_on.png" />승인 / 반려</p>
                            </div>
                            <div class="step step3">
                                <p><img src="@/assets/drawable-xxxhdpi/checkin.png" />체크인</p>
                            </div>
                            <!--
								<div class="step step3 on">
									<p><img src="../img/drawable-xxxhdpi/checkin_on.png"/>체크인</p>
								</div>
								-->
                            <div class="step step4">
                                <p><img src="@/assets/drawable-xxxhdpi/checkout.png" />체크아웃</p>
                            </div>
                            <!--
								<div class="step step4 on">
									<p><img src="../img/drawable-xxxhdpi/checkout_on.png"/>체크아웃</p>
								</div>
								-->
                        </div>
                    </div>
                </div>
            </div>
            <div class="detail_area">
                <div class="detail_info">
                    <h2 class="header_line">방문신청 상세내용</h2>
                    <div class="detail_img">
                        <img src="@/assets/drawable-xxxhdpi/company_ico.png" />
                    </div>
                    <div class="detail_txt">
                        <ul>
                            <li class="gray_txt"><span class="detail_t">방문지역</span>GS건설 본사</li>
                            <li class="gray_txt"><span class="detail_t">방문객 명</span>이민호</li>
                            <li class="gray_txt"><span class="detail_t">예약번호</span>1234</li>
                            <li class="gray_txt"><span class="detail_t">휴대전화번호</span>010-1234-1234</li>
                            <li class="gray_txt"><span class="detail_t">차량번호</span>123 가 1234</li>
                            <li class="gray_txt"><span class="detail_t">장소 / 목적</span>GS건설 본사 1층 로비</li>
                            <li class="gray_txt"><span class="detail_t">방문기간</span>2021-02-04 10:40 ~ 2021-02-04 12:40</li>
                            <li class="gray_txt"><span class="detail_t">찾아갈 분</span>이민호</li>
                            <li class="gray_txt"><span class="detail_t">방문상태</span>승인 / 반려</li>
                        </ul>
                    </div>
                </div>
                <div class="cancel_area">
                    <button id="cancel_btn" class="green_line btn">방문 취소</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'VisitReserveDetail',
        methods: {
            didClickBack(){
                this.$router.go(-1)
            }
        }
    }
</script>