<template>
<!-- 1:1 문의하기 -->
    <div>
        <header>
            <h1 class="title_line"><a href="javascript:history.back();"> &#60; </a>1:1문의<a v-show="selectIndex == 0"
                  @click="didClickSave" class="save">완료</a></h1>
        </header>
        <div id="content">
            <div class="inquiry">
                <div class="inquiry_area">
                    <div class="inquiry_tab">
                        <ul>
                            <li :class="{on: selectIndex === 0}" @click="didClickTab(0)"><a>문의하기</a></li>
                            <li :class="{on: selectIndex === 1}" @click="didClickTab(1)"><a>문의내역</a></li>
                        </ul>
                    </div>
                    <QandAWrite v-if="selectIndex == 0" ref="QandAWrite"></QandAWrite>
                    <FAQList v-else></FAQList>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
    import QandAWrite from '../components/views/QandAWrite'
    import FAQList from '../components/views/FAQList'
    import AlertModal from '../components/modals/AlertModal'

    export default {
        name: 'QandA',
        components: {
            FAQList,
            QandAWrite
        },
        data() {
            return {
                selectIndex: 1
            }
        },
        methods: {
            didClickSave() {

                this.$refs.QandAWrite.didQnaClickSave();

   
            },
            didClickTab(idx) {
                this.selectIndex = idx
            }
        }
    }
</script>