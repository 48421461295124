<template>
<!-- 방문 장소 수정 -->
    <div id="wrap">
			<header>
				<h1 class="title_line"><a @click="didClickExit"> &#60; </a>방문장소 수정<a @click="clickConfirm" class="save">수정</a></h1>
			</header>
			<div id="content">
				<div class="gray_bg">
					<div class="location_add">
						<div class="apply_form">
							<p class="mod_message"><span class="red">틀린 / 추가할 정보</span>가 있다면 수정해주세요.</p>
							<label class="red_form form_label">
								<span class="form_title">장소 이름</span>
								<input type="text" name="keyword" class="name form" placeholder="장소 이름입력" v-model="placeName"/>
								<button class="txt_clear btn" @click="placeName = ''">지우기</button>
							</label>
							<p class="error_m">등록하실 장소 이름을 입력해주세요.</p>
							<label class="red_form form_label">
								<span class="form_title">주소</span>
								<input type="text" name="keyword" class="company form" placeholder="예) 해운대구 센텀동로 99" v-model="placeAdress"/>
								<button class="txt_clear btn" @click="placeAdress = ''">지우기</button>
							</label>
							<div class="company_map" id="company_map"></div>
							<p class="error_m">주소를 입력해주세요.</p>
							<label class="red_form form_label">
								<span class="form_title">방문신청 페이지 주소</span>
								<input type="text" name="keyword" class="duty form" placeholder="예) http://naver.com 또는 https://naver.com" v-model="homePage"/>
								<button class="txt_clear btn" @click="homePage = ''">지우기</button>
							</label>
							<p class="error_m">방문신청 페이지 주소를 입력해주세요.</p>
						</div>
					</div>
				</div>
				<div class="memo">
					<div class="apply_form">
						<label class="form_label note_area">
							<span class="form_title">기타정보</span>
							<textarea type="text" class="note" placeholder="잘못된 정보가 있다면 알려주세요."
							ref="commentInputRef" v-on:keyup="textCountdown" v-model="compDesc" required></textarea><span
							id="counter">{{commentLength}} / 1000</span></label>
					</div>
				</div>
			</div>
		</div>
</template>
<script>
import ComfirmModal from './modals/ConfirmModal'
import AlertModal from './modals/AlertModal'

import {
	updateVisitPlace
} from '@/api/common'

export default {
    name: 'EditVisitPlace',
	data(){
		return {
			commentLength: 0,
			lon : this.mapCompItems.comp_lon,
			lat : this.mapCompItems.comp_lat,
		}
	},
	props: {
		mapCompItems: Array
	},
    methods: {
		textCountdown() {
				let inputComment = this.$refs.commentInputRef.value
                if (inputComment.length > 1000) {
                    this.$refs.commentInputRef.value = inputComment.substr(0, 1000)
                }
                this.commentLength = this.$refs.commentInputRef.value.length
			},
        didClickExit(){
            this.$modal.show(ComfirmModal,{
                content: '현재 작성된 내용이 사라집니다.<br>취소하시겠습니까?',
                goHome: false,
                goBack: true
            },{
                name: 'ComfirmModal'
            })
        },
        clickConfirm(){

			if(this.placeName == ""){
				this.$modal.show(AlertModal, {
					content: '장소 이름을 입력하세요.',
					goHome: false,
					goBack: false
				}, {
					name: 'AlertModal'
				})
				return false;
			}else if(this.placeAdress == ""){
				this.$modal.show(AlertModal, {
					content: '주소를 입력하세요.',
					goHome: false,
					goBack: false
				}, {
					name: 'AlertModal'
				})
				return false;
			}

			const form = new FormData()


			form.append('seq'  , this.mapCompItems.seq)
			form.append('placeName'  , this.placeName)
			form.append('placeAdress', this.placeAdress)
			form.append('homePage'   , this.homePage)
			form.append('lon'        , this.lon)
			form.append('lat'        , this.lat)
			form.append('compDesc'   , this.compDesc)
			form.append('userKey'    , localStorage.getItem('tel'))

			updateVisitPlace(form).then(response => {
				console.log(response)
				if(response.status == 200){

					console.log("response.data.list : " +response.data.list);

					this.$modal.show(AlertModal, {
						content: '방문장소 수정 요청이 완료되었습니다.<br>관리자 확인후 빠르게 반영하겠습니다.<br>감사합니다.',
						goHome: true,
						goBack: false
					}, {
						name: 'AlertModal'
					})

				}
			})
        }
    },
	mounted() {


		var map = new naver.maps.Map('company_map', {
			center: new naver.maps.LatLng(this.lat, this.lon), //지도의 초기 중심 좌표
			zoom: 16, //지도의 초기 줌 레벨
			disableKineticPan:false,
			mapDataControl:false,
			clickable:true,
			mapTypeId: naver.maps.MapTypeId.NORMAL
		});

		if(this.mapCompItems.comp_flag == "C"){
			var greenNlobby = new naver.maps.Marker({
				position: new naver.maps.LatLng(this.lat, this.lon),
				map: map,
				icon: {
					content: [
								'<div class="mapbridge green_nlobby">',
									'<div class="map_group _map_group">',
										'<div class="map_marker _marker"> ',
											'<span class="ico _icon"></span>',
											'<span class="shd">마커</span>',
										'</div>',
									'</div>',
								'</div>'
							].join(''),
					size: new naver.maps.Size(40, 45),
					anchor: new naver.maps.Point(19, 40),
				},
				draggable: false
			});

		}else if(this.mapCompItems.comp_flag == "N"){
			var grayNlobby = new naver.maps.Marker({
				position: new naver.maps.LatLng(this.mapCompItems.comp_lat, this.mapCompItems.comp_lon),
				map: map,
				icon: {
					content: [
								'<div class="mapbridge gray_nlobby">',
									'<div class="map_group _map_group">',
										'<div class="map_marker _marker"> ',
											'<span class="ico _icon"></span>',
											'<span class="shd">마커</span>',
										'</div>',
									'</div>',
								'</div>'
							].join(''),
					size: new naver.maps.Size(40, 45),
					anchor: new naver.maps.Point(19, 40),
				},
				draggable: false
			});
		}




	},
	created() {

		this.placeName = this.mapCompItems.comp_name;
		this.placeAdress = this.mapCompItems.comp_addr;
		this.homePage = this.mapCompItems.comp_url;
		this.compDesc = this.mapCompItems.comp_desc;

		if (this.homePage === 'undefined') {
			this.homePage = "";
		}

		if (this.compDesc === 'undefined') {
			this.compDesc = "";
		}
		
	}
}
</script>