<template>
<!-- 임직원 등록 -->
     <div id="wrap">
			<header>
				<h1 class="title_line"><a @click="didClickBack"> &#60; </a>임직원 등록</h1>
			</header>
			<div id="content">
				<div class="member_cert">
					<h1 class="comple_title"><b>임직원 인증</b></h1>
					<p class="black_txt"><span class="green">방문예약시스템</span>을 이용중이신가요?</p>
					<p>인증을 진행하시면 더욱 편하고 많은 기능을<br>이용하실 수 있습니다.<br><br>아래 버튼을 터치해서 인증을 진행 해 주세요.</p>
				</div>
				<div class="staff_area">
					<button onclick="location.href='company_find.html'" id="staff_btn" class="green_line btn">임직원 인증</button>
				</div>
			</div>
		</div>
  
</template>
<script>
export default {
    name: 'CompanyInfo',
    methods: {
        didClickBack(){
            this.$router.go(-1)
        }
    }
}
</script>