import Vue from 'vue'
import VueGeolocationApi from 'vue-geolocation-api'
import App from './App.vue'
import router from './router/index'
import Store from './store/index'
import VModal from 'vue-js-modal'
import Unicon from 'vue-unicons/dist/vue-unicons-vue2.umd'
import { uniAngleRight, uniBars} from 'vue-unicons/dist/icons'
import naver from 'vue-naver-maps';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import loader from "vue-ui-preloader";
import 'swiper/swiper-bundle.css'
import { Swiper as SwiperClass, Pagination, Mousewheel, Autoplay, Navigation } from 'swiper/core'
import VueClipboard from 'vue-clipboard2'

VueClipboard.config.autoSetContainer = true // add this line

window.Kakao.init("ba132e7ead70cb648b5b214a561698f7");

Vue.use(VueClipboard)
Vue.use(loader);
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
SwiperClass.use([Pagination, Mousewheel, Autoplay, Navigation])
Vue.use(naver, {
  clientID: 'Client ID',
  useGovAPI: false, //공공 클라우드 API 사용 (선택)
  subModules:'' // 서브모듈 (선택)
});

Unicon.add([uniAngleRight,uniBars])
Vue.use(Unicon)

Vue.prototype.$store = Store
Vue.prototype.$EventBus = new Vue()
Vue.use(VModal, {
  dynamic: true
})

Vue.use(VueGeolocationApi/*, { ...options } */)

Vue.config.productionTip = false

new Vue({
  router,
  Store,
  render: h => h(App),
}).$mount('#app')