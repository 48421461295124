<template>
<!-- 설정 -->
    <div>
        <div id="wrap">
			<header>
				<h1 class="title_line"><a @click="didClickBack"> &#60; </a>사용자 정보</h1>
			</header>
			<div id="content" class="gray_bg">
				<div class="mypage_area">
					<div class="mypage">
						<h2 class="header_line">내 정보</h2>
						<div class="mypage_info">
							<p class="my_name">이름<a @click="didClickEdit('이름',name)">{{name}}</a></p>
							<p class="my_pany">소속회사<a @click="didClickEdit('소속회사',company)">{{company}}</a></p>
							<p class="my_rank">직책<a @click="didClickEdit('직책',position)">{{position}}</a></p>
							<p class="my_car">차량번호<a @click="didClickEdit('차량번호',carNumber)">{{carNumber}}</a></p>
							<p class="my_phone">휴대전화번호<a @click="didClickEdit('휴대전화번호',tel)">{{tel}}</a></p>
							<p class="my_mail">이메일 주소<a @click="didClickEdit('이메일주소',email)">{{email}}</a></p>
						</div>
					</div>
				</div>
				<!--
				<div class="change_company">
					<div class="setup_area">
						<h2 class="header_line">임직원 회사정보</h2>
						<div class="member_info">
							<p class="mb_company">엔로비</p>
							<div class="change_btn">
								<button class="delete_btn btn">삭제</button>
								<button class="change_btn btn" onclick="location.href='company_find.html';">변경</button>
							</div>
						</div>
					</div>
				</div>
	
				<div class="visit_setup" v-show="false">
					<div class="setup_area">
						<h2 class="header_line">방문페이지 공유링크<br>
						<span class="sub_header">메인페이지 좌측메뉴에 있는 방문페이지주소를 공유합니다.</span></h2>
						<div class="visit_link">
							<label id="link_form" class="form_label">
								<input class="form" type="text" placeholder="주소입력" v-model="visitAdress"/>
								<button class="txt_clear btn" @click="visitAdress = ''">지우기</button>
							</label>							
							<button class="btn green_bg">저장</button>
						</div>
					</div>
				</div>
			-->
				<div class="visit_setup" >
					<div class="setup_area">
						<h2 class="header_line">
							<span class="sub_header">*사용자 정보 입력은 필수가 아닌 선택사항이며, 입력된 사용자 정보는 서버로 전송되지 않습니다.</span><br>
							<span class="sub_header">*사용자 정보를 입력하시면 추후 방문신청시 자동 입력기능을 이용하실수 있습니다. </span>
						</h2>
						<!--
						<div class="visit_link">							
							<button class="btn green_bg">저장</button>
						</div>
						-->
					</div>
				</div>
				<div class="alarm_setup">
					<div class="setup_area">
						<h2 class="header_line">알림</h2>
						<div class="apply_form">
							<p class="push_alarm">PUSH 알림</p>
							<label class="toggle_check">
								<input type="checkbox" class="real-checkbox">
								<div class="toggle-button round"></div>
							</label>
						</div>
					</div>
				</div>
				<div class="my_reset">
					<p class="warning">개인정보 초기화를 하실경우<br> 저장된 모든 데이터가 사라집니다. 주의하세요.</p>
					<p class="reset_btn"><a @click="clickResetInfo">개인정보 초기화</a></p>
				</div>
			</div>
		</div>
	<!-- <div id="reset_popup" class="nlobby_modal">
			<div class="popup_area">
				<div class="popup_bg">
					<div class="pupup_con">
						<div class="popup_txt">
							<h4 class="pop_title">개인정보 초기화 하실경우<br>저장된 모든 데이터가 사라집니다.<br>초기화 하시겠습니까?</h4>
							<div class="pop_btn">
								<button id="cancel_btn" class="cancel_btn btn">취소</button>
								<button class="confirm_btn green_bg btn">확인</button>
							</div>
						</div>
						<div class="modal_bg"></div>
					</div>
				</div>
			</div>
		</div> -->
		<div id="delete_popup" class="nlobby_modal">
			<div class="popup_area">
				<div class="popup_bg">
					<div class="pupup_con">
						<div class="popup_txt">
							<h4 class="pop_title">현재 인증된 임직원 소속회사를<br>삭제하시겠습니까?</h4>
							<div class="pop_btn">
								<button id="cancel_btn" class="cancel_btn btn">취소</button>
								<button id="delete_complet" class="confirm_btn green_bg btn">확인</button>
							</div>
						</div>
						<div class="modal_bg"></div>
					</div>
				</div>
			</div>
		</div>

		<div id="delete_complet_pop" class="nlobby_modal">
			<div class="popup_area">
				<div class="popup_bg">
					<div class="pupup_con">
						<div class="popup_txt">
							<h4 class="pop_title">삭제완료되었습니다.<br>재 등록 시 좌측 메뉴에서<br>임직원 등록을 이용바랍니다.</h4>
							<button class="confirm_btn green_bg btn">확인</button>
						</div>
						<div class="modal_bg"></div>
					</div>
				</div>
			</div>
		</div>

    </div>
</template>
<script>
import ConfirmModal from '@/components/modals/ConfirmModal'
export default {
    name: 'MyPage',
	data(){
		return {
			name: localStorage.getItem('name'),
			company: localStorage.getItem('company'),
			position: localStorage.getItem('position'),
			carNumber: localStorage.getItem('carNumber'),
			tel: localStorage.getItem('tel'),
			email: localStorage.getItem('email'),
			visitAdress: ''
		}
	},
    components: {
    },
	methods: {
		clickResetInfo(){
			this.$modal.show(ConfirmModal,{
				content: '개인정보 초기화 하실경우<br>저장된 모든 데이터가 사라집니다.<br>초기화 하시겠습니까?'
			},{
				name: 'ConfirmModal'
			})
		},
		didClickEdit(title,cont){
			this.$router.push({name: 'MYPAGEEDIT',params: {
				title,
				cont
			}})
		},
		didClickBack(){
			this.$router.go(-1)
		}

	}
}
</script>