<template>
<!-- 지도 위치별 팝업 - 지역정보가 없는 경우 -->
    <div class="nodata_com company_pop" id="nodata_com" :class="{close : showNoDataCompany == false, open : showNoDataCompany == true}">
            <button class="close_btn" @click="closeNoDataCompany">X</button>
            <div class="list_title" id="list_title">
                <h4 class="place_name">엔로비 부산본사</h4>
                <p class="place_address">해운대구 센텀동로 99 벽산E센텀클래스원 1119호 해운대구 센텀동로 99 벽산E센텀클래스원 1119호 해운대구 센텀동로 99 벽산E센텀클래스원 1119호</p>
                <div class="place_plus">
                    <p class="place_add_btn"><a @click="didClickBtn">방문장소 만들기</a></p>
                </div>
            </div>
        </div>
</template>
<script>
export default {
    name: 'NoDataCompany',
    props: {
        showNoDataCompany: Boolean
    },
    methods: {
        didClickBtn(){
            this.$router.push({name: 'SAVEVISITPLACE'})
        },
        closeNoDataCompany(){
            this.$emit('closeNoDataCompany')
        }
    }
}
</script>