<template>
    <!-- 1:1문의 에서 문의하기 -->
    <div>
        <div class="quiry_form">
            <div class="custom_select">
                <label class="select_form" for="select">{{qnaType.text }}</label>
                <select class="normal_select" for="select" v-model="qnaType" >
                    <option v-for="option in qnaComCodeList" v-bind:value="{ id: option.co_up_cd, text: option.co_nm }" v-bind:key="option.co_nm">
                        {{ option.co_nm }}
                    </option>
                </select>
            </div>
            <label><input type="text" class="form" v-model="qnaTitle" placeholder="제목을 적어주세요" /></label>
            <label class="note_area">
                <textarea type="text" class="note" v-model="qnaContent" placeholder="자세한 내용을 적어주세요"
                    ref="commentInputRef" v-on:keyup="textCountdown" required></textarea>
                    <span id="counter">{{commentLength}} / 1000</span></label>
        </div>
        <div class="upload">
            <div class="upload_area">
                <input type="file" id="file"/>
                <p class="upload_btn" @click="selectUploadFile">업로드 버튼</p>
                <swiper ref="mySwiper" :options="swiperOptions" class="swiper">
                    <swiper-slide v-for="(item,index) in filesSeq" :key="`upload-list-key-${index}`" class="swiper-slide">
                        <div class="item img_view">
                            <a class="del_ico" @click="fileDeleteButton" :name="item.thumbnailUrl">삭제버튼</a>
                            <div class="img_thumbanil">
                                <p><img :src="item.thumbnailUrl" /></p>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
                <p class="not_message">사진은 최대 5장까지 추가 가능합니다.</p>
            </div>
        </div>
    </div>
</template>
<script>
	import AlertModal from '@/components/modals/AlertModal'
    import {
        getComCodeList,
        fileUpload,
        saveQna
    } from '@/api/common'

    export default {
        name: "QandAWrite",
        components: {},
        data() {
            return {
                commentLength: 0,
                qnaComCodeList: [],
                files: [], 
                filesSeq: [], 
                swiperOptions: {
                    slidesPerView: 'auto',
                    spaceBetween: 15,
                    freeMode: true,
                    loop: false
                },
                qnaType: {id:'', text:'문의유형을 선택해주세요.'}
            }
        },
        methods: {
            textCountdown() {
            
                let inputComment = this.$refs.commentInputRef.value
                if (inputComment.length > 1000) {
                    this.$refs.commentInputRef.value = inputComment.substr(0, 1000)
                }
                this.commentLength = this.$refs.commentInputRef.value.length
            },
            didQnaClickSave(){

                const form = new FormData()

                console.log(JSON.stringify(this.filesSeq))

                form.append('qnaType', this.qnaType.id)

                console.log(this.qnaType.id)

                form.append('qnaTitle', this.qnaTitle)

                console.log(this.qnaTitle)
                form.append('qnaContent', this.qnaContent)

                console.log(this.qnaContent)
                form.append('filesSeq', JSON.stringify(this.filesSeq))
                form.append('userKey', localStorage.getItem('tel'))

                console.log(localStorage.getItem('tel'))

                console.log(JSON.stringify(form))

                saveQna(form).then(response => {
                    console.log(response)
                    if(response.status == 200){

                        this.$modal.show(AlertModal, {
                            content: '1:1문의 신청이 완료되었습니다.<br>관리자 확인후 빠르게 답변해드리겠습니다.<br>감사합니다.',
                            goHome: false,
                            goBack: false
                        }, {
                            name: 'AlertModal'
                        })

                        this.$parent.didClickTab(1);

                    }
                })


                

            },
            selectUploadFile() {


                var vue = this
                let elem = document.createElement('input')
                // 이미지 파일 업로드 / 동시에 여러 파일 업로드
                elem.id = 'image'
                elem.type = 'file'
                elem.accept = 'image/*'
                elem.multiple = true


                document.body.appendChild(elem);

                // 클릭
                elem.click();
                // 이벤트 감지
                elem.onchange = function() {


                    if(this.files.length > 5){
                        vue.$modal.show(AlertModal, {
                            content: '사진은 최대 5장까지 추가 가능합니다.',
                            goHome: false,
                            goBack: false
                        }, {
                            name: 'AlertModal'
                        })

                        return;

                    }
                    
                    const form = new FormData()

                    for (var index = 0; index < this.files.length; index++) {
                        form.append('fileList', this.files[index])
                    }

                    fileUpload(form).then(response => {
                        console.log(response)
                        if(response.status == 200){
                            
                            vue.filesSeq = response.data.list;

                            console.log("fileUpload : " +response.data.list.length + " / " + vue.filesSeq.length + " / " + vue.filesSeq);
                        }
                    })

                }
            },
            fileDeleteButton(e) {
                const name = e.target.getAttribute('name');
                this.filesSeq = this.filesSeq.filter(data => data.thumbnailUrl !== name);
            },
        },
        created() {

            let form = new FormData()
            form.append('coCd', "QNA")
            
            getComCodeList(form).then(response => {
                console.log(response)
                if(response.status == 200){

                    this.qnaComCodeList = response.data.list;	
                }
			})
        }
    }
</script>
<style>
    .swiper {
        margin-bottom: 30px !important;
    }

    .swiper-slide {
        width: 85px !important;
    }
</style>