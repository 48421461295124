<template>
<!-- 사이드 메뉴 -->
	<div class="left_area" :class="{close : showSideMenu == false, open : showSideMenu == true}">
		<div class="left_scroll">
			<div class="user_info">
				<h4 class="user_name">{{name}}</h4>
				<button class="close_btn" @click="closeSideMenu">X</button>
				<!-- <p class="user_company">엔로비<span class="user_mb">임직원</span></p> -->
				<div class="user_ep">
					<p class="user_email">{{company}}</p>
					<p class="user_phone">{{tel}}</p>
				</div>
			</div>
			<div class="menu_list">
				<div class="nlobby_banner">
					<a @click="clickBotBtn(0)"></a>
				</div>
				<div class="left_menu scroll">
					<div class="common_menu">
						<h2 class="menu_title">방문장소</h2>
						<ul>
							<li class="visit_seaech"><a @click="didClickVisit">방문신청</a></li>
							<li class="share-my">앱 추천하기<br><span class="summary">방문객, 동행인 등 비즈잇이 필요한 분에게 추천해주세요.</span>
								<div class="targets">
									<a class="sns_btn sms_ico" @click="didClickSms">
									</a>
									<a class="sns_btn kakao_ico" @click="didClickKakao">
									</a>
									<a class="sns_btn email_ico" @click="didClickEmail">
									</a>
									<a class="sns_btn more_ico" @click="didClickShare">
									</a>
								</div>
							</li>
						</ul>
					</div>
					<!--
								<div class="user_menu">
									<h2 class="menu_title">사용자 메뉴</h2>
									<ul>
										<li><a href="company_find.html">임직원 등록<br><span class="summary">엔로비 고객사 임직원 전용</span></a></li>
										<li><a href="company_find.html">회사 변경<br><span class="summary">소속회사가 변경되었나요?</span></a></li>
										<li><a href="#">공유하기<br><span class="summary">방문하시는 분에게 정보를 공유하세요.</span></a></li>
										<li><a href="#">방문신청 확인<br><span class="summary">현재 방문신청 진행상황이 궁금하신가요?</span></a></li>
										<li><a href="https://demo.nlobby.com/dash">방문관리 페이지<br><span class="summary">방문신청 승인 및 취소</span></a></li>
									</ul>
								</div>
								-->
				</div>
			</div>
			<div class="left_bot">
				<div class="left_bot_menu">
					<ul>
						<li class="guide_ico"><a @click="clickUserFace">이용안내</a></li>
						<li class="home_ico"><a @click="clickBotBtn(0)">홈페이지</a></li>
						<li class="custom_ico"><a @click="clickBotBtn(1)">고객센터</a></li>
						<li class="set_ico"><a @click="clickBotBtn(2)">설정</a></li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'SideMenu',
		data(){
			return{
				tel: '',
				company: '',
			}
		},
		props: {
			showSideMenu: Boolean,
			sideCompList: []
		},
		methods: {
			clickUserFace(){
				this.$emit('closeSideMenu')
				this.$emit('clickUserFace')
			},
			clickBotBtn(idx){
				if(idx == 0){

					try {
						var message = {action: "visitGo", shareUrl: "http://nlobby.com"  };
						window['webkit'].messageHandlers['cordova_iab'].postMessage(JSON.stringify(message));
					} catch (err) {
						window.open(comp_url, "_blank")
					}
				}else if(idx == 1){
					this.$router.push({name: "CSCENTER"})
				}else if(idx == 2){
					this.$router.push({name: "MYPAGE"})
				}
			},
			closeSideMenu() {
				this.$emit('closeSideMenu')
			},
			didClickVisit(){
				this.$emit('closeSideMenu')
                this.$router.push({
					name: 'HOMESEARCH',
					params: {compItems: this.sideCompList, pgFlag : 'H'}
                })
			},
			didClickKakao(){

                let tmpMsg = '"방문신청은 비즈잇, 이제부터 방문신청은 비즈잇 하나로 해결됩니다"';

                let tmpUrl = "";

                const varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

                console.log("varUA : " +varUA + " / " + varUA.toLowerCase().indexOf('android'));

				if ( varUA.toLowerCase().indexOf('android') > -1) {
					//안드로이드
					tmpMsg += '\n\n구글(안드로이드)플레이 스토어 설치 페이지 링크 : https://play.google.com/store/apps/details?id=com.nlobby.visit';
					tmpUrl = "https://play.google.com/store/apps/details?id=com.nlobby.visit";

				} else if ( varUA.indexOf("iphone") > -1||varUA.indexOf("ipad") > -1||varUA.indexOf("ipod") > -1 ) {
					//IOS
					tmpMsg += '\n\n애플(IOS) 스토어 설치 페이지 링크 : https://apps.apple.com/kr/app/id6444391758';

					tmpUrl = "https://apps.apple.com/kr/app/id6444391758";

				}

				console.log("tmpUrl : " +tmpUrl);

                Kakao.Link.sendDefault({
                    objectType: 'feed',
                    content: {
                        title: '방문신청은 비즈잇',
                        description: tmpMsg,
                        imageUrl:
                            'https://play-lh.googleusercontent.com/JSCm-VWq4-ZUIaSOkf3SQqWPjK_IXTJ19qM0L8SKN8kD4q5wFEaFNMZVcYv2V6yaaEo=w416-h235-rw',
                        link: {
                            mobileWebUrl: tmpUrl,
                            webUrl: tmpUrl,
                        }
                    },
                    buttons: [
                        {
                            title: '설치하기',
                            link: {
                                mobileWebUrl: tmpUrl,
                                webUrl: "tmpUrl",
                            },
                        },
                    ],
                })
			},
			didClickShare(){

			    const varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

                let tmpMsg = "";

                let tmpUrl = "";

				if ( varUA.indexOf('android') > -1) {
					tmpMsg += 'https://play.google.com/store/apps/details?id=com.nlobby.visit';

				} else if ( varUA.indexOf("iphone") > -1||varUA.indexOf("ipad") > -1||varUA.indexOf("ipod") > -1 ) {
					tmpMsg += 'https://apps.apple.com/kr/app/id6444391758';

				}


				var message =   {action: "share", 
								subject: "비즈잇",
								message: "방문신청은 비즈잇, 이제부터 방문신청은 비즈잇 하나로 해결됩니다",
								shareUrl: tmpMsg };
				
				window['webkit'].messageHandlers['cordova_iab'].postMessage(JSON.stringify(message));
			},
			didClickSms(){

				const varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

				var m;

                //let 은 변수에 재할당이 가능하다. const는 변수 재선언, 변수 재할당 모두 불가능하다. (변수 재선언이 되지 않는다)
                let tmpMsg = '"방문신청은 비즈잇, 이제부터 방문신청은 비즈잇 하나로 해결됩니다"';


				if ( varUA.indexOf('android') > -1) {
					//안드로이드
					m = "android";
					tmpMsg += '\n\n구글(안드로이드)플레이 스토어 설치 페이지 링크 : https://play.google.com/store/apps/details?id=com.nlobby.visit';

				} else if ( varUA.indexOf("iphone") > -1||varUA.indexOf("ipad") > -1||varUA.indexOf("ipod") > -1 ) {
					//IOS
					m = "ios";
					tmpMsg += '\n\n애플(IOS) 스토어 설치 페이지 링크 : https://apps.apple.com/kr/app/id6444391758';

				} else {
					//아이폰, 안드로이드 외
					m = "other";
				}

                if ( varUA.indexOf('android') > -1) {
                    location.href = 'sms:' +''+(m == 'ios' ? '&' : '?') + 'body='+ encodeURIComponent(tmpMsg);

                } else if ( varUA.indexOf("iphone") > -1||varUA.indexOf("ipad") > -1||varUA.indexOf("ipod") > -1 ) {

                    var message =   {action: "tel",
                                    message: tmpMsg};

                    window['webkit'].messageHandlers['cordova_iab'].postMessage(JSON.stringify(message));
                }
			},
			didClickEmail(){

				const varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

				var m;
                //let 은 변수에 재할당이 가능하다. const는 변수 재선언, 변수 재할당 모두 불가능하다. (변수 재선언이 되지 않는다)
                let tmpMsg = '"방문신청은 비즈잇, 이제부터 방문신청은 비즈잇 하나로 해결됩니다"';


				if ( varUA.indexOf('android') > -1) {
					//안드로이드
					m = "android";
					tmpMsg += '\n\n구글(안드로이드)플레이 스토어 설치 페이지 링크 : https://play.google.com/store/apps/details?id=com.nlobby.visit';

				} else if ( varUA.indexOf("iphone") > -1||varUA.indexOf("ipad") > -1||varUA.indexOf("ipod") > -1 ) {
					//IOS
					m = "ios";
					tmpMsg += '\n\n애플(IOS) 스토어 설치 페이지 링크 : https://apps.apple.com/kr/app/id6444391758';

				} else {
					//아이폰, 안드로이드 외
					m = "other";
				}


                if ( varUA.indexOf('android') > -1) {
                    location.href='mailto:' + '?' +(m == 'ios' ? '&' : '?')+ '&body=' + encodeURIComponent(tmpMsg);

                } else if ( varUA.indexOf("iphone") > -1||varUA.indexOf("ipad") > -1||varUA.indexOf("ipod") > -1 ) {

                    var message =   {action: "mail",
                                    message: tmpMsg};

                    window['webkit'].messageHandlers['cordova_iab'].postMessage(JSON.stringify(message));
                }
			},
		},
        created(){
			this.tel = localStorage.getItem('tel');
			this.company = localStorage.getItem('company');
			this.name = localStorage.getItem('name');
        }
	}
</script>
<style lang="scss" scoped>
</style>