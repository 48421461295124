<template>
<!-- 마이페이지 - 정보 수정 -->
    <div>
        <header>
            <h1 class="title_line"><a @click="didClickBack">
                    &#60; </a>{{title}} <a @click="didClickSave" class="save">저장
                </a></h1>
        </header>
        <div id="content" class="mod_form">
            <div class="form_box">
                <label id="name_form" class="form_label">
                    <input type="text" name="keyword" class="name_input form" :placeholder="title + '입력'" v-model="cont"/>
                    <button class="txt_clear btn" @click="cont = ''">지우기</button>
                </label>
            </div>
        </div>
    </div>
</template>
<script>
    import AlertModal from './modals/AlertModal'
    export default {
        name: 'MyPageEdit',
        components:{
           
        },
		props: {
			title: String,
			cont: String
		},
		methods: {
            didClickSave(){

                if(this.title == "이름"){
                    localStorage.setItem('name', this.cont);
                }else if(this.title == "소속회사"){
                    localStorage.setItem('company', this.cont);
                }else if(this.title == "직책"){
                    localStorage.setItem('position', this.cont);
                }else if(this.title == "차량번호"){
                    localStorage.setItem('carNumber', this.cont);
                }else if(this.title == "이메일주소"){
                    localStorage.setItem('email', this.cont);
                }else if(this.title == "휴대전화번호"){
                    localStorage.setItem('tel', this.cont);
                }
                
                this.$modal.show(AlertModal,{
                    content: '저장되었습니다',
                    goHome: false,
                    goBack: true
                }, {name: 'AlertModal'})
            },
			didClickBack(){
				this.$router.go(-1)
			}
		}
    }
</script>