<template>
<!-- Home 화면 주변/최근/즐겨찾기 모달 -->
	<div>
		<div class="bot_list list_location" :class="{close : showBotMenu == false, open : showBotMenu == true}">
			<h4 class="bot_title" v-if="ListIndex === 0">주변<span class="bot_sub">현재 가까운 곳에 있습니다.</span></h4>
			<h4 class="bot_title" v-else-if="ListIndex === 1">즐겨찾기</h4>
			<h4 class="bot_title" v-else>최근</h4>
			<button class="list_close" @click="closeBotMenu">X</button>
			<ul class="scroll">
				<li v-for="(item,index) in bottomItems" :key="`Home-Reserve-List-key-${index}`">
					<a href="javascript:void(0)" @click="didVisitGoBtn(item.comp_url)">
						{{item.comp_name}}<br>
						<span class="list_address">{{item.comp_addr}}</span>
					</a>
					<div class="list_btn">
						<p class="bookmark_ico" @click="clickBookMark(item.seq, item.favorite)" :class="{on: item.favorite == 'F'}">즐겨찾기</p>
						<p class="map_move_ico" @click="clickGoHome(item.comp_lat, item.comp_lon)">위치이동</p>
					</div>
				</li>

			</ul>
		</div>
	</div>
</template>
<script>
	import AlertModal from '../modals/AlertModal'
	
    import {
		getBottomCompList,
		getFavoriteList,
		setFavorite,
		delFavorite
	} from '@/api/common'
	
	export default {
		name: 'HomeBottomList',
		data(){
			return {
				isBookMark: false
			}
		},
		props: {
			showBotMenu: Boolean,
			ListIndex: Number,
			bottomItems: Array
		},
		methods: {

			clickGoHome(comp_lat, comp_lon){
				this.$parent.clickGoHome(comp_lon, comp_lat);
				//this.$parent.hiddenNoMark(false);
			},
            didVisitGoBtn(comp_url){

                console.log("comp_url :" + comp_url);
                try {
                    var message = {action: "visitGo", shareUrl: comp_url };
                    window['webkit'].messageHandlers['cordova_iab'].postMessage(JSON.stringify(message));
                } catch (err) {
                    window.open(comp_url, "_blank")
                }
            },
			clickBookMark(seq, favorite){

                let form = new FormData()
                form.append('seq', seq)
				form.append('tel', localStorage.getItem('tel'))
				
				if(this.ListIndex == 0 || this.ListIndex == 2){

					if(favorite == "F"){

						this.$modal.show(AlertModal, {
							content: '이미 추가된 고객사입니다.',
							goHome: false,
							goBack: false
						}, {
							name: 'AlertModal'
						})
						
						return false;
					}

					setFavorite(form).then(response => {
						console.log(response)
						if(response.status == 200){

							this.$parent.clickBotMenu(this.ListIndex)
						}
					})
				}else if(this.ListIndex == 1){
					delFavorite(form).then(response => {
						console.log(response)
						if(response.status == 200){

							this.getFavoriteList();
						}
					})
				}


			},
			getFavoriteList(){
				let form = new FormData()
				form.append('tel', localStorage.getItem('tel'))
				
				getFavoriteList(form).then(response => {
					console.log(response)
					if(response.status == 200){

						this.bottomItems = response.data.list;
					}
				})
			},
			closeBotMenu() {
				this.$emit('closeBotMenu')
			}
		},
        mounted() {

        },
	}
</script>