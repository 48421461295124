<template>
    <div id="wrap">
			<div id="flow_search" class="flow_search scroll">
				<div class="search_area">
					<div class="f_search">
						<button class="back_btn" id="back_btn"> &#60; </button>
						<button class="txt_clear btn">지우기</button>
						<input type="search" name="keyword" class="place_form" id="searchbox" placeholder="주소 / 회사명 검색"/>
						<button class="search_ico btn">검색아이콘</button>
					</div>
				</div>
			</div>
			<header>
				<h1 class="title_line"><a @click="didClickBack"> &#60; </a>임직원 등록</h1>
			</header>
			<div id="content">
				<div class="gray_bg">
					<div class="company_find">
						<div class="search_area">
							<h2 class="header_line">소속회사 검색</h2>
							<div class="search_bar">
								<input type="text" name="keyword" class="search place_form" placeholder="주소 / 회사명 검색"/>
								<p class="gray_txt">검색을 허용하지 않은 고객사는 표시되지 않을 수 있습니다.</p>
							</div>
						</div>
					</div>
				</div>
				<div class="search_info">
					<div class="result_area">
						<h2 class="search_result"><span class="green">엔로비</span> 검색결과</h2>
						<!--
						<div class="error_message">
							<p class="gray_txt">검색어를 입력하세요.</p>
							<p class="gray_txt">검색결과가 없습니다.</p>
						</div>
						-->
						<div class="company_list">
							<ul>
								<li class="list">
									<div class="company_info">
										<a href="company_login.html">엔로비<br>
										<span class="sub_address">부산광역시 해운대구 센텀동로 99 1119호</span></a>
										<button class="map_ico">지도 아이콘</button>
									</div>
									<div class="company_map" id="company_map"></div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
</template>
<script>
export default {
    name: 'CompanyFind',
    methods: {
        didClickBack(){
            this.$router.go(-1)
        }
    }
}
</script>