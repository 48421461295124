<template>
<!-- 이용약관 상세 -->
    <div id="wrap">
			<header>
				<h1 class="title_line"><a @click="didClickBack"> &#60; </a>{{title}}</h1>
			</header>
            <div class="term-detail">
                <div class="term-detail-inner">
                    <p>여긴 내용 영역입니다</p>
                </div>
            </div>
    </div>
</template>
<script>
export default {
    name: 'AgreeTermDetail',
    props: {
        title: String
    },
    methods: {
        didClickBack(){
            this.$router.go(-1)
        }
    }
}
</script>
<style lang="scss" scoped>
.term-detail {
    width: 100%;
    height: calc(100vh - 60px);
    padding: 15px;
    box-sizing: border-box;
    background-color: #e6e6e6;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    .term-detail-inner {
        width: 100%;
        height: 100%;
        background: #fff;
        padding: 15px;
        box-sizing: border-box;
    }
}
</style>